import React from "react";
import { useNavigate } from "react-router-dom";

import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/bg_login_form.png";

import logo from "images/logo_progreenfood.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AuthService from "services/auth.service";
import useUserToken from "store/user_token";
import useUserData from "store/user_data";
import toast from "react-hot-toast";

const Container = tw(
  ContainerBase
)`min-h-screen bg-[#424445] text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-2xl m-0 sm:mx-10 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-4 sm:p-10`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-16 mx-auto`;
const MainContent = tw.div`mt-2 flex flex-col items-center`;
const Heading = tw.h1`text-xl xl:text-2xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-4`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-yellow-600 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1  hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw` w-full  bg-cover bg-center bg-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  headingText = "Recuperación de Contraseña",
  submitButtonText = "Iniciar Recuperación",
  SubmitButtonIcon = LoginIcon
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [, userTokenActions] = useUserToken();
  const [, userDataActions] = useUserData();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    //console.log(data);
    AuthService.recover_password(data).then((value) => {
      if (value.status == 200) {
        if (value.data) {
          toast.success("Recuperación efectuada. Recibirá un email con instrucciones para validar la nueva contraseña introducida");
          setTimeout(function() {
            window.location.href = "/";
          }, 1500);
        } else {
          toast.error("Error de Acceso.");
        }
      } 
    }).catch(
      function (error) {
        toast.error("Datos Introducidos Erróneos.");
        return Promise.reject(error)
      }
    );
  };

  return (
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <p tw="mt-6 text-sm text-gray-600 text-center">
                Introduce tu correo electrónico y la nueva
                contraseña deseada
              </p>

            <FormContainer>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  type="email"
                  required
                  placeholder="Correo Electrónico"
                  {...register("email")}
                />
                <Input
                  type="text"
                  required
                  placeholder="Nueva Contraseña"
                  {...register("password")}
                />

                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>

              <p tw="mt-6 text-xs text-gray-600 text-center">
                Recibirás por correo electrónico un
                enlace para validar la nueva contraseña introducida
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
        <IllustrationImage tw="rounded-r-lg" imageSrc={illustration} />
        </IllustrationContainer>
        
      </Content>
    </Container>
  );
};
