import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HeroQuienesSomos from "components/pds/HeroQuienesSomos";

import InfoEmpresas from "components/pds/InfoEmpresas";

import QuienesSomosEmpresas from "components/pds/QuienesSomosEmpresas";
import Footer from "components/pds/FooterFiveColumns.js";
import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";

export default () => (
  <>
  <StyledHeaderMenu />
  <AnimationRevealPage disabled>
    <HeroQuienesSomos />
    <QuienesSomosEmpresas />
    
    <InfoEmpresas />

    <Footer />
  </AnimationRevealPage>
  </>

);
