import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeadingLite2xl } from "components/misc/Headings.js";


import IcoFactor from "../../images/IconFactor.png";

import informe from "images/informe.png";

// Estilos
const Container = tw.div`relative mb-4`;
const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-2 md:py-1`}
`;
const Heading = tw(
  SectionHeadingLite2xl
)`w-full font-bold text-gray-700 mt-4 text-2xl`;
const HeadingLight = tw(
  SectionHeadingLite2xl
)`w-full font-normal text-gray-700 mt-3 text-base mb-4`;
const Column = styled.div`
  ${tw`md:w-1/2 sm:w-full   px-1 flex mb-2`}
`;

const FeatureList = tw.ul`mt-1  `;
const Feature = tw.li`flex m-1`;
const FeatureText = tw.p`ml-3 font-medium text-left`;
const Plan = styled.div`
  ${tw`w-full  mt-4 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-white bg-[#f9f9f9] flex flex-col `}
  .big_text {
    ${tw`text-xl`}
  }

  .textContainer {
    ${tw`mt-1 text-center`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-2 flex-1 text-sm text-[#8b8c8e]`}
  .feature {
    ${tw` first:mt-0 font-semibold text-gray-500`}
  }
`;

/**
 * Componente Principal
 */
export default () => {
  return (
    <Container>
      <FourColumnContainer>
        <Heading>EL IMPACTO DE TU ESTRATEGIA DE SOSTENIBILIDAD</Heading>
        <HeadingLight>
          Analiza en un sencillo informe (con más de 90 métricas de impacto) tu
          contribución a la triple sostenibilidad e identifica las áreas de
          mejora prioritarias en tu estrategia.
        </HeadingLight>

        <Column key="1">
          <Plan>
            <img tw="w-full" src={informe} />
          </Plan>
        </Column>

        <Column key="3">
          <Plan>
            <PlanFeatures>
              <FeatureList>
                <Feature>
                  <img tw="p-1 w-6 h-6" src={IcoFactor} alt="" />
                  <FeatureText>
                    Aprovecha una herramienta diseñada por y para el sector
                    agroalimentario, con un cuestionario adaptado a tu realidad.
                  </FeatureText>
                </Feature>
                <br />
                <Feature>
                  <img tw="p-1 w-6 h-6" src={IcoFactor} alt="" />
                  <FeatureText>
                    Compara tus resultados contra empresas similares a la tuya
                    (en base a tamaño, facturación y posición en la cadena de
                    valor) y frente a las empresas que tienen las mejores
                    prácticas (referentes).
                  </FeatureText>
                </Feature>
                <br />
                <Feature>
                  <img tw="p-1 w-6 h-6" src={IcoFactor} alt="" />
                  <FeatureText>
                    Utiliza el informe como input para adaptar tu estrategia en
                    inversiones futuras en materia de sostenibilidad.
                  </FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>
      </FourColumnContainer>
    </Container>
  );
};
