import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundHomeSystem from "images/home.jpg";
import homeCircleInfo from "images/home_circle_info_ng.png";
import homeLogoFull from "images/logo_full.png";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${backgroundHomeSystem});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8  mx-auto `;

const TwoColumn = tw.div`pt-16 pb-8 px-4 flex flex-col justify-between items-center lg:flex-row md:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center w-full  lg:w-4/6 sm:w-full pt-0`;
const RightColumn = tw.div`flex flex-col items-center w-full lg:w-2/6 sm:w-full pt-4 sm:w-auto p-8`;

const HeadingLite = styled.h1`
  ${tw`text-3xl text-left lg:text-left sm:text-4xl lg:text-3xl xl:text-3xl text-gray-100 leading-none mt-0`}
  span {
    ${tw`inline-block mt-0`}
  }
`;

const LogoLeft = tw.span`w-3/6 content-start mb-5`;
const HeadingBis = tw.span`pt-5 text-base`;

const ColumnContainer = tw.div`pt-3 max-w-lg`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-start  mt-4 lg:mt-4 flex-col sm:flex-row`;
const Link = tw.a`rounded w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#424445] hocus:bg-gray-600 text-gray-100 shadow-lg  hocus:text-gray-200`;

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <HeadingLite tw="lg:mt-0 mt-8">

              <span tw="text-[#F6F6F6] text-5xl">
                Gestiona tu impacto en sostenibilidad
              </span>

              <HeadingBis tw="text-[#F6F6F6]">
                MIDE EL IMPACTO MEDIOAMBIENTAL, SOCIAL Y ECONÓMICO DE TU ORGANIZACIÓN AGROALIMENTARIA
              </HeadingBis>

              <LinksContainer>
                <PrimaryLink tw="bg-[#494948] hocus:bg-[#c7aa45]" href="/mide_inicio">
                  COMENZAR
                </PrimaryLink>
              </LinksContainer>
            </HeadingLite>
          </LeftColumn>

          <RightColumn tw="mt-12">
            <img src={homeCircleInfo} />
          </RightColumn>
        
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
