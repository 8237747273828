import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HeroHeaderActua from "components/pds/HeroHeaderActua";
import HeroContentActua from "components/pds/HeroContentActua";
import FeaturesActua from "components/pds/FeaturesActua";

import GetStarted from "components/pds/GetStarted";
import Footer from "components/pds/FooterFiveColumns.js";

export default () => (
  <AnimationRevealPage>
    <HeroHeaderActua />
    <HeroContentActua />
    <FeaturesActua />
    <GetStarted />
    <Footer />
  </AnimationRevealPage>
);