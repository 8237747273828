import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "images/logo_progreenfood.png";
import LogoPatrocinadores from "images/logo_patrocinadores.png";

const Container = tw.div`relative bg-[#424445] text-gray-100  -mx-8 px-8 py-2`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const LogoContainer = tw.div`flex items-center justify-center `;
const LogoImg = tw.img`w-80 p-2`;
const CopywrightNotice = tw.p`text-center text-xs  text-gray-400`;

/**
 * Definicion del Componente
 */
export default () => {
  return (
    <div style={{position: 'relative', bottom: -12}} tw="w-full">
      <Container tw="bg-white mb-0">
        <Content>
          <div tw="">
            <div tw="grid grid-cols-12 ">
              <div tw="col-span-4 flex justify-center items-center">
                <LogoContainer>
                  <LogoImg src={LogoImage} />
                </LogoContainer>
              </div>
              <div tw="col-span-7  justify-center items-center">
                <div>
                  <LogoContainer tw="w-full  justify-center items-center">
                    <img src={LogoPatrocinadores} />
                  </LogoContainer>
                </div>
              </div>
              <div tw="col-span-1 flex justify-center items-center"></div>
            </div>
          </div>
        </Content>
      </Container>
      <Container tw="mt-0 left-0 right-0 bg-[#424445] " >
        <CopywrightNotice tw="">
          &copy; 2023 Nagrifood. Todos los derechos reservados.
        </CopywrightNotice>
      </Container>
    </div>
  );
};
