import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MiCuenta from "components/pds/MiCuenta";
import Footer from "components/pds/FooterFiveColumns.js";
import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";

export default () => (
  <>
    <StyledHeaderMenu />
    <AnimationRevealPage disabled>
      <MiCuenta />
      <Footer />
    </AnimationRevealPage>
  </>
);