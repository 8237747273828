import axios from "axios";
import configData from "config.json";

class EvaluationService {
  /**
   * Añade una SelectedCharacterizationQuestion a una StartedEvaluation
   * 
   * @returns SelectedCharacterizationQuestion
   */
  add_selected_characterization_question(characterization_question) {
    const api_url = configData.API_BASE_URL + "characterization_question/add_to_started_evaluation";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
      .post(api_url, {
        started_evaluation_id: characterization_question.started_evaluation_id,
        characterization_question_id: characterization_question.characterization_question_id,
        type_selected: characterization_question.type_selected,
        option_selected: characterization_question.option_selected,
        value_selected: characterization_question.value_selected
      },
      { 
        headers: {"Authorization" : `Bearer ${userToken}`}
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Añade un checkbox a una SelectedQuestion de una StartedEvaluation
   */
  add_selected_checkbox_question(question) {
    const api_url = configData.API_BASE_URL + "question/add_checkbox_to_started_evaluation";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
    .post(api_url, {
      started_evaluation_id: question.started_evaluation_id,
      question_id: question.question_id,
      type_selected: question.type_selected,
      option_selected: question.option_selected,
      value_selected: JSON.stringify(question.value_selected)
    },
    { 
      headers: {"Authorization" : `Bearer ${userToken}`}
    })
    .then((response) => {
      return response;
    });
  }

  /**
 * Añade un checkbox a una SelectedQuestion de una StartedEvaluation
 */
  add_selected_multiple_question(question) {
    const api_url = configData.API_BASE_URL + "question/add_multiple_to_started_evaluation";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
    .post(api_url, {
      started_evaluation_id: question.started_evaluation_id,
      question_id: question.question_id,
      type_selected: question.type_selected,
      option_selected: question.option_selected,
      value_selected: JSON.stringify(question.value_selected)
    },
    { 
      headers: {"Authorization" : `Bearer ${userToken}`}
    })
    .then((response) => {
      return response;
    });
  }

  /**
   * Añade una SelectedQuestion a una StartedEvaluation
   */
  add_selected_question(question) {
    const api_url = configData.API_BASE_URL + "question/add_to_started_evaluation";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
      .post(api_url, {
        started_evaluation_id: question.started_evaluation_id,
        question_id: question.question_id,
        type_selected: question.type_selected,
        option_selected: question.option_selected,
        value_selected: question.value_selected
      },
      { 
        headers: {"Authorization" : `Bearer ${userToken}`}
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Añade una SelectedQuestion a una StartedEvaluation
   */
  add_or_remove_conditional_question(started_evaluation, question, option) {
    const api_url = configData.API_BASE_URL + "started_evaluation/add_or_remove_conditional_question";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
      .post(api_url, {
        started_evaluation_id: started_evaluation.id,
        question_id: question.id,
        option: option
      },
      { 
        headers: {"Authorization" : `Bearer ${userToken}`}
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Actualización de una StartedEvaluation
   */
  update_started_evaluation(started_evaluation) {
    const api_url = configData.API_BASE_URL + "started_evaluation/update_started_evaluation";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
      .post(api_url, {
        id: started_evaluation.id,
        pillar_id: started_evaluation.pillar_id,
        factor_id: started_evaluation.factor_id,
        current_question_position: started_evaluation.current_question_position,
        current_question_id: started_evaluation.current_question_id,
        finished_economic_pillar: started_evaluation.finished_economic_pillar,
        finished_social_pillar: started_evaluation.finished_social_pillar,
        finished_environment_pillar: started_evaluation.finished_environment_pillar,
        finished_estrategia_factor: started_evaluation.finished_estrategia_factor,
        finished_clientes_factor: started_evaluation.finished_clientes_factor,
        finished_innovacion_factor: started_evaluation.finished_innovacion_factor,
        finished_resultado_factor: started_evaluation.finished_resultado_factor,
        finished_responsabilidad_factor: started_evaluation.finished_responsabilidad_factor,
        finished_calidad_factor: started_evaluation.finished_calidad_factor,
        finished_trabajadores_factor: started_evaluation.finished_trabajadores_factor,
        finished_desarrollo_factor: started_evaluation.finished_desarrollo_factor,
        finished_cumplimiento_factor: started_evaluation.finished_cumplimiento_factor,
        finished_envases_factor: started_evaluation.finished_envases_factor,
        finished_eficiencia_factor: started_evaluation.finished_eficiencia_factor,
        finished_emisiones_factor: started_evaluation.finished_emisiones_factor,
        finished: started_evaluation.finished
      },
      { 
        headers: {"Authorization" : `Bearer ${userToken}`}
      })
      .then((response) => {

        return response;
      });   
  }

  generate_started_evaluation_questions(started_evaluation) {
    const api_url = configData.API_BASE_URL + "started_evaluation/generate_questions";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
    .post(api_url, {
      id: started_evaluation.id
    },
    { 
      headers: {"Authorization" : `Bearer ${userToken}`}
    })
    .then((response) => {
      return response;
    });  
  }

  generate_started_evaluation_feedbacks(started_evaluation) {
    const api_url = configData.API_BASE_URL + "started_evaluation/generate_feedbacks";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
    .post(api_url, {
      id: started_evaluation.id
    },
    { 
      headers: {"Authorization" : `Bearer ${userToken}`}
    })
    .then((response) => {
      return response;
    });  
  }
}

export default new EvaluationService();