import React, { useEffect } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import toast from "react-hot-toast";

//import Hero from "components/pds/BackgroundAsImage.js";
import Hero from "components/pds/HeroHeaderHome.js";
import Features from "components/pds/DashedBorderFourFeatures";
import ContactUsForm from "components/pds/TwoColContactUsFullForm.js";
import Footer from "components/pds/FooterFiveColumns.js";
import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";
import ImpactBlock from "components/pds/ImpactBlock";
import StartingPointBlock from "components/pds/StartingPointBlock";

/**
 * Componente Principal
 */
export default () => {

  useEffect(() => {
    let mensaje = localStorage.getItem('mensaje');
    if (mensaje) {
      toast.success(mensaje, {duration: 5000});
      localStorage.removeItem("mensaje");
    }
  });

  return (
  <>
    <StyledHeaderMenu />
    <AnimationRevealPage disabled>
      <Hero />
      <StartingPointBlock />
      <Features />
      <ImpactBlock />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  </>
);
}
