import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeadingLite2xl } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import IcoEconomiaColor from "../../images/IcoEconomiaBlanco.png";
import IcoSocialColor from "../../images/IcoSocialBlanco.png";
import IcoMedioambientalColor from "../../images/IcoMedioambienteBlanco.png";
import IcoClock from "../../images/IconClock.png";
import IcoFactor from "../../images/IconFactor.png";
import IcoQuestion from "../../images/IconQuestion.png";
import IcoCasa from "../../images/IconCasa.png";

// Estilos
const Container = tw.div`relative mb-4`;
const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-2 md:py-1`}
`;
const Heading = tw(
  SectionHeadingLite2xl
)`w-full font-bold text-gray-700 mt-12 text-3xl`;
const HeadingLight = tw(
  SectionHeadingLite2xl
)`w-full font-normal text-gray-700 mt-3 text-base mb-4`;
const Column = styled.div`
  ${tw`w-4/5 sm:w-4/5 md:w-2/5 lg:w-2/5 px-1 flex mb-2`}
`;
const Intro = styled.div`
  ${tw`md:w-full lg:w-full bg-[#f9f9f9] p-2 pl-4   rounded-lg text-base text-gray-700 mb-4`}
`;
const FeatureList = tw.ul`mt-1  `;
const Feature = tw.li`flex m-1`;
const FeatureText = tw.p`ml-3 font-medium text-left`;
const Plan = styled.div`
  ${tw`w-full  mt-4 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-white bg-[#f9f9f9] flex flex-col `}
  .big_text {
    ${tw`text-xl`}
  }

  .textContainer {
    ${tw`mt-1 text-center`}
  }

  img {
    ${tw`w-6 h-6`}
  }
`;
const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-1 -mx-8 bg-[#494948 ] rounded-t-lg`}
`;
const PlanHeader1 = styled.div`
  ${tw`flex flex-col leading-relaxed py-1 -mx-8 bg-[#F0A000 ] rounded-t-lg`}
`;
const PlanHeader2 = styled.div`
  ${tw`flex flex-col leading-relaxed py-1 -mx-8 bg-[#867796 ] rounded-t-lg`}
`;
const PlanHeader3 = styled.div`
  ${tw`flex flex-col leading-relaxed py-1 -mx-8 bg-[#41AD2B] rounded-t-lg`}
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-2 flex-1 text-sm text-[#8b8c8e]`}
  .feature {
    ${tw` first:mt-0 font-semibold text-gray-500`}
  }
`;
const ColumnContainer = tw.div`pt-1 max-w-lg`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-center  mt-4 lg:mt-1 flex-col sm:flex-row`;
const Link = tw.a`rounded w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-1 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#494948] text-gray-100 shadow-lg hocus:bg-[#41AD2B] hocus:text-gray-200`;

/**
 * Componente Principal
 */
export default () => {
  return (
    <Container>
      <FourColumnContainer>
        <Heading>MIDE TU IMPACTO</Heading>
        <HeadingLight>
          Bienvenid@! Estás a punto de conocer el nivel de sostenibilidad de tu
          empresa
        </HeadingLight>

        <Intro>
          <span tw="font-bold">
            Este itinerario de evaluación se divide en 4 fases
          </span>{" "}
          en el que deberás responder a las preguntas seleccionando las
          respuesta(s) de acuerdo con la situación de tu empresa.
        </Intro>

        <Column key="1">
          <Plan>
            <PlanHeader>
              <span tw="absolute pl-2 font-bold text-xl">FASE I</span>
              <div align="center">
                <img src={IcoCasa} alt="" />
              </div>

              <span className="textContainer">
                <span className="big_text">CARACTERIZACIÓN EMPRESAS</span>
              </span>
            </PlanHeader>

            <PlanFeatures>
              <FeatureList>
                <Feature>
                  <img tw="p-1" src={IcoQuestion} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TOTAL PREGUNTAS:</span> 12
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoClock} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TIEMPO ESTIMADO:</span> 5 minutos
                  </FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>

        <Column key="3">
          <Plan>
            <PlanHeader1>
              <span tw="absolute pl-2 font-bold text-xl">FASE II</span>
              <div align="center">
                <img src={IcoEconomiaColor} alt="" />
              </div>

              <span className="textContainer">
                <span className="big_text">EVALUACIÓN PILAR ECONÓMICO</span>
              </span>
            </PlanHeader1>
            <PlanFeatures>
              <FeatureList>
                <Feature>
                  <img tw="p-1" src={IcoFactor} alt="" />
                  <FeatureText>
                    <span tw="font-normal">Nº DE FACTORES: </span> 4
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoQuestion} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TOTAL PREGUNTAS:</span> 11
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoClock} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TIEMPO ESTIMADO:</span> 5 minutos
                  </FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>

        <Column key="2">
          <Plan>
            <PlanHeader2>
              <span tw="absolute pl-2 font-bold text-xl">FASE III</span>
              <div align="center">
                <img src={IcoSocialColor} alt="" />
              </div>

              <span className="textContainer">
                <span className="big_text">EVALUACIÓN PILAR SOCIAL</span>
              </span>
            </PlanHeader2>
            <PlanFeatures>
              <FeatureList>
                <Feature>
                  <img tw="p-1" src={IcoFactor} alt="" />
                  <FeatureText>
                    <span tw="font-normal">Nº DE FACTORES: </span> 4
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoQuestion} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TOTAL PREGUNTAS:</span> 30
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoClock} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TIEMPO ESTIMADO:</span> 15 minutos
                  </FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>

        <Column key="3">
          <Plan>
            <PlanHeader3>
              <span tw="absolute pl-2 font-bold text-xl">FASE IV</span>
              <div align="center">
                <img src={IcoMedioambientalColor} alt="" />
              </div>

              <span className="textContainer">
                <span className="big_text">
                  EVALUACIÓN PILAR MEDIOAMBIENTAL
                </span>
              </span>
            </PlanHeader3>
            <PlanFeatures>
              <FeatureList>
                <Feature>
                  <img tw="p-1" src={IcoFactor} alt="" />
                  <FeatureText>
                    <span tw="font-normal">Nº DE FACTORES: </span> 4
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoQuestion} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TOTAL PREGUNTAS:</span> 24
                  </FeatureText>
                </Feature>
                <Feature>
                  <img tw="p-1" src={IcoClock} alt="" />
                  <FeatureText>
                    <span tw="font-normal">TIEMPO ESTIMADO:</span> 15 minutos
                  </FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>
      </FourColumnContainer>



      <FourColumnContainer tw="mt-6">
        <LinksContainer>
          <PrimaryLink href="/mide_evaluacion">
            ACCEDER A LA EVALUACIÓN {">>"}
          </PrimaryLink>
        </LinksContainer>
      </FourColumnContainer>

      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};
