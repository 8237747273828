import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

//import Hero from "components/pds/BackgroundAsImage.js";
import FeaturesMide from "components/pds/FeaturesMide";
import Footer from "components/pds/FooterFiveColumns.js";

import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";
import HeroMide from "components/pds/HeroMide";

export default () => (
  <>
    <StyledHeaderMenu />
    <AnimationRevealPage disabled>
      <HeroMide />
      <FeaturesMide />
      <Footer />
    </AnimationRevealPage>
  </>
);
