import axios from "axios";
import configData from "config.json";

class AuthService {
  /**
   * Login en el api remoto, devuelve un token que será utilizado
   * para poder realizar llamadas al API
   */
  login(email, password) {
    const login_api_url = configData.API_BASE_URL + "login";

    return axios
      .post(login_api_url, {
        email: email,
        password: password,
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Preregistro en el api remoto.
   */
  register(data) {
    const register_api_url = configData.API_BASE_URL + "register";

    return axios
      .post(register_api_url, {
        company: data.company,
        cif: data.cif,
        email: data.email,
        password: data.password,
        name: data.name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        postal_code: data.postal_code,
        state: data.state,
        position: data.position
      })
      .then((response) => {
        console.log("respuesta registro");
        console.log(response);
        return response;
      });
  }

  /**
   * Inicio recuperación de contraseña.
   */
  recover_password(data) {
    const api_url = configData.API_BASE_URL + "recover_password";

    return axios
      .post(api_url, {
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        console.log(response);
        return response;
      });
  }

  /**
   * Procesamiento de los datos del formulario de Contacto
   */
  process_contact_form(data) {
    const api_url = configData.API_BASE_URL +  "process_contact_form";

    return axios
      .post(api_url, {
        name: data.name,
        last_name: data.last_name,
        company: "",
        email_company: data.email_company,
        content: data.content
      })
      .then((response) => {
        console.log("respuesta actualizacion datos");
        console.log(response);
        return response;
      });    
  }

  /**
   * Actualización de los datos de un Usuario
   */
  update_data_user(data) {
    const api_url = configData.API_BASE_URL +  "user/update";
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    return axios
      .post(api_url, {
        email: data.email,
        name: data.name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        postal_code: data.postal_code,
        state: data.state,
        position: data.position
      },{ 
        headers: {"Authorization" : `Bearer ${userToken}`}
      })
      .then((response) => {
        console.log("respuesta actualizacion datos");
        console.log(response);
        return response;
      });    
  }

  /**
   * Checkea la existencia del nombre de la empresa
   */
  check_company(company_cif) {
    const login_api_url = configData.API_BASE_URL + "check_company";

    return axios
      .post(login_api_url, {
        company_cif: company_cif
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Obtención de los datos del usuario registrado
   * @returns UserData
   */
  user() {
    const user_api_url = configData.API_BASE_URL + "user";
    let user_token = localStorage.getItem("user_token");
    if (user_token) {
      user_token = JSON.parse(user_token);
    }

    return axios
      .get(user_api_url, {
        headers: {
          Authorization: `Bearer ${user_token}`,
        },
      })
      .then((response) => {
        return response;
      });
  }
}

export default new AuthService();