import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeadingLite2xl } from "components/misc/Headings.js";

import IcoEconomiaColor from "images/IcoEconomiaBlanco.png";
import IcoSocialColor from "images/IcoSocialBlanco.png";
import IcoMedioambientalColor from "images/IcoMedioambienteBlanco.png";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

const Container = tw.div`relative`;

const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-5 md:py-10`}
`;
const Heading = tw(SectionHeadingLite2xl)`w-full font-bold text-gray-700`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 w-full px-4 flex`}
`;

const Card = styled.div`
  ${tw`bg-gray-100 flex flex-col mx-auto max-w-xs items-center px-4 py-6  rounded-lg mt-8`}
  .imageContainer {
    ${tw`border-0 border-yellow-600 text-center rounded-full p-4 flex-shrink-0 relative `}
    img {
      ${tw`w-5 h-5`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-yellow-600`}
  }

  .description {
    ${tw`mt-3 font-semibold text-yellow-600 text-sm leading-loose`}
  }
`;

const FeatureList = tw.ul`mt-0 leading-loose `;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5`;
const FeatureText = tw.p`ml-2 font-medium`;

const Plan = styled.div`
  ${tw`w-full  mt-8 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-white bg-[#f9f9f9] flex flex-col `}

  .big_text {
    ${tw`text-xl`}
  }

  .textContainer {
    ${tw`mt-1 text-center`}
  }

  img {
    ${tw`w-10 h-10`}
  }
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-4 -mx-8 bg-[#F0A000 ] rounded-t-lg`}


`;

const PlanHeader1 = styled.div`
  ${tw`flex flex-col leading-relaxed py-4 -mx-8 bg-[#F0A000 ] rounded-t-lg`}


`;

const PlanHeader2 = styled.div`
  ${tw`flex flex-col leading-relaxed py-4 -mx-8 bg-[#867796 ] rounded-t-lg`}


`;

const PlanHeader3 = styled.div`
  ${tw`flex flex-col leading-relaxed py-4 -mx-8 bg-[#41AD2B] rounded-t-lg`}


`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-4 flex-1 text-sm text-[#8b8c8e]`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

export default () => {

  return (
    <Container>
      <FourColumnContainer>
        <Heading>
          ¿Quieres conocer cómo ProgreeNFood mide el nivel de sostenibilidad de tu
          organización?

        </Heading>
        <span tw="w-full text-base mt-4 p-3">
        La estrategia de sostenibilidad de la empresa se mide teniendo en cuenta 3 pilares principales: sostenibilidad económica, social y medioambiental. 
Para medir las prácticas de gestión relacionadas con estas estrategias, cada uno de estos 3 pilares se divide en 4 factores competitivos. Estos factores sirven como palanca de transformación competitiva, traduciendo las estrategias globales en focos de acción medibles y accionables.
        </span>

        <Column key="1">
          <Plan>
            <PlanHeader>
            <div align="center">
                <img src={IcoEconomiaColor} alt="" />
              </div>

              <span className="textContainer">
                <span className="title">Sostenibilidad</span>
                <br />
                <span className="big_text">ECONÓMICA</span>
              </span>
            </PlanHeader>

            <PlanFeatures>

              <FeatureList>
                <Feature key="1">
                  <FeatureIcon />
                  <FeatureText>Estrategia</FeatureText>
                </Feature>
                <Feature key="1">
                  <FeatureIcon />
                  <FeatureText>Clientes</FeatureText>
                </Feature>
                <Feature key="1">
                  <FeatureIcon />
                  <FeatureText>Innovación</FeatureText>
                </Feature>
                <Feature key="1">
                  <FeatureIcon />
                  <FeatureText>Resultados e impacto económico</FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>

        <Column key="2">
          <Plan>
            <PlanHeader2>
              <div align="center">
                <img src={IcoSocialColor} alt="" />
              </div>

              <span className="textContainer">
                <span className="title">Sostenibilidad</span>
                <br />
                <span className="big_text">SOCIAL</span>
              </span>
            </PlanHeader2>
            <PlanFeatures>


              <FeatureList>
                <Feature key="1">
                  <FeatureIcon />
                  <FeatureText>Responsabilidad social y gobernanza</FeatureText>
                </Feature>
                <Feature key="2">
                  <FeatureIcon />
                  <FeatureText>Calidad alimentaria y nutricional</FeatureText>
                </Feature>
                <Feature key="3">
                  <FeatureIcon />
                  <FeatureText>Trabajadores</FeatureText>
                </Feature>
                <Feature key="4">
                  <FeatureIcon />
                  <FeatureText>Desarrollo de la comunidad</FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>

        <Column key="3">
          <Plan>
            <PlanHeader3>
            <div align="center">
                <img src={IcoMedioambientalColor} alt="" />
              </div>

              <span className="textContainer">
                <span className="title">Sostenibilidad</span>
                <br />
                <span className="big_text">MEDIOAMBIENTAL</span>
              </span>
            </PlanHeader3>
            <PlanFeatures>
               <FeatureList>
                <Feature key="1">
                  <FeatureIcon />
                  <FeatureText>Cumplimiento ambiental</FeatureText>
                </Feature>
                <Feature key="2">
                  <FeatureIcon />
                  <FeatureText>Envases</FeatureText>
                </Feature>
                <Feature key="3">
                  <FeatureIcon />
                  <FeatureText>Eficiencia en el consumo</FeatureText>
                </Feature>
                <Feature key="4">
                  <FeatureIcon />
                  <FeatureText>Emisiones y residuos</FeatureText>
                </Feature>
              </FeatureList>
            </PlanFeatures>
          </Plan>
        </Column>
      </FourColumnContainer>

    </Container>
  );
};
