import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundExpertSystem from "images/company_head.jpg";
import companyImage from "images/logo_eroski_min.jpg";

import Header, {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover  min-h-48`}
  background-image: url(${backgroundExpertSystem});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`mb-5 mt-5 text-2xl text-center sm:text-4xl lg:text-4xl xl:text-4xl  text-gray-100 leading-snug  sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PreHeading = styled.div`
  ${tw`mb-2 mt-2 text-2xl  sm:text-4xl lg:text-4xl xl:text-4xl font-bold text-gray-100 content-center mx-auto object-contain`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Inicio</NavLink>
      <NavLink href="/expert_system">Sistema Experto</NavLink>
      <NavLink href="/knowledge_hub">Knowledge Hub</NavLink>
      <NavLink href="#">Servicios de Mejora</NavLink>
      <NavLink href="#">Buenas Prácticas</NavLink>

      <NavLink href="/quienes_somos">Quienes Somos</NavLink>
      <NavLink href="/faqs">FAQ's</NavLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <PreHeading>
          <img src={companyImage} alt="empresa"></img>
        </PreHeading>
        <Content>
          <Heading>Sistemas expertos</Heading>
        </Content>
      </HeroContainer>
    </Container>
  );
};
