import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ResultadosEvaluacionInformeAdmin from "components/pds/ResultadosEvaluacionInformeAdmin";


/**
 * Componente Principal
 */
export default () => {

  return (
    <>

      <AnimationRevealPage disabled>
        <ResultadosEvaluacionInformeAdmin 
        />

      </AnimationRevealPage>
    </>
  );
};