import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";

import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { SectionHeadingLite } from "components/misc/Headings.js";

const Heading = tw(SectionHeadingLite)`w-full mb-10`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-row flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3 `;
const HeadingColumnRight = tw(Column)`w-full xl:w-2/3`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-5 `;
const HeadingTitle = tw.h2`text-3xl tracking-wide  xl:text-left leading-tight`;
const HeadingDescription = tw.div`font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const ColumnT = styled.div`
  ${tw`lg:w-1/6 max-w-xs`}
  .number {
    ${tw`font-black text-6xl p-4`}
  }
`;
const ColumnDT = styled.div`
  ${tw`lg:w-2/6 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-left h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none text-primary-500`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <Heading>
          Una herramienta que te ofrece soluciones para todas tus necesidades en
          materia de <span tw="text-primary-500">Sostenibilidad</span>
        </Heading>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>
                Kit de preparación para el diagnóstico
              </HeadingTitle>
              <HeadingDescription>
                Antes de empezar, echa un vistazo al kit que te ayudará a
                identificar todo lo que necesitas saber para poder realizar el
                diagnostico de forma rápida y eficaz
              </HeadingDescription>
              <PrimaryLink>
                Ver Más <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>

          <HeadingColumnRight>
            <HeadingInfoContainer>
              <HeadingTitle>Sistemas Expertos</HeadingTitle>
              <HeadingDescription>
                <ThreeColumnContainer>
                  <ColumnT>
                    <Card>
                      <a href="/mide">
                        <span className="number">1</span>
                      </a>
                      <a href="/mide">
                        <span className="title">MIDE</span>
                      </a>
                    </Card>
                  </ColumnT>
                  <ColumnT>
                    <Card>
                      <a href="/valida">
                        <span className="number">2</span>
                      </a>
                      <a href="/valida">
                        <span className="title">VALIDA</span>
                      </a>
                    </Card>
                  </ColumnT>
                  <ColumnT>
                    <Card>
                      <a href="/genera">
                        <span className="number">3</span>
                      </a>
                      <a href="/genera">
                        <span className="title">GENERA</span>
                      </a>
                    </Card>
                  </ColumnT>
                  <ColumnT>
                    <Card>
                      <a href="/actua">
                        <span className="number">4</span>
                      </a>
                      <a href="/actua">
                        <span className="title">ACTUA</span>
                      </a>
                    </Card>
                  </ColumnT>
                  <ColumnDT>
                    <Card>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua
                      <PrimaryLink>
                        Ver Más <ArrowRightIcon />
                      </PrimaryLink>
                    </Card>
                  </ColumnDT>
                </ThreeColumnContainer>
              </HeadingDescription>
            </HeadingInfoContainer>
          </HeadingColumnRight>
        </ThreeColumn>
      </Content>
    </Container>
  );
};
