import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/bg_login_form.png";

import ReactModalAdapter from "helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import logo from "images/logo_progreenfood.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AuthService from "services/auth.service";
import useUserToken from "store/user_token";
import useUserData from "store/user_data";
import toast from "react-hot-toast";

const Container = tw(
  ContainerBase
)`min-h-screen bg-[#424445] text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-2xl m-0 sm:mx-10 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-4 sm:p-10`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-16 mx-auto`;
const MainContent = tw.div`mt-2 flex flex-col items-center`;
const Heading = tw.h1`text-xl xl:text-2xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-4`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-yellow-600 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1  hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw` w-full  bg-cover bg-center bg-repeat`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  logoLinkUrl = "/",
  headingText = "Regístrate en Progreenfood",
  submitButtonText = "Iniciar Registro",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "#",
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [, userTokenActions] = useUserToken();
  const [, userDataActions] = useUserData();
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  /**
   * Verificación del formato del CIF
   */
  const check_cif = (cif) => {
    if (!cif || cif.length !== 9) {
      return false;
    }

    var letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var i;
    var digit;

    if (!letter.match(/[A-Z]/)) {
      return false;
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
        return false;
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = parseInt(digit / 10) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  };

  const onSubmit = (data) => {
    AuthService.check_company(data.cif)
      .then((value) => {
        if (value.data.success) {
          // Comenzamos el proceso de registro
          if (value.data.data === "OK") {
            toast.error("ERROR: El CIF suministrado ya ha sido registrado.");
          } else {
            if (check_cif(data.cif)) {
              AuthService.register(data).then((value) => {
                if (value.status == 200) {
                  if (value.data) {
                    //toast.success("Pre-registro efectuado. Recibirá un email con instrucciones para finalizar el Registro");
                    let msg =
                      "Pre-registro efectuado. Recibirá un email con instrucciones para finalizar el Registro.";
                    localStorage.setItem("mensaje", msg);
                    window.location.href = "/";
                  } else {
                    toast.error("Error de Acceso.");
                  }
                }
              });
            } else {
              toast.error("ERROR: El CIF suministrado es inválido.");
            }
          }
        } else {
          toast.error("Error de Acceso.");
        }
      })
      .catch((exeption) => {
        toast.error("Error de Acceso");
      });
  };

  return (
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  type="email"
                  required
                  placeholder="Correo Electrónico"
                  {...register("email")}
                />
                <Input
                  type="password"
                  required
                  placeholder="Contraseña"
                  {...register("password")}
                />
                <Input
                  type="text"
                  placeholder="Nombre"
                  required
                  {...register("name")}
                />
                <Input
                  type="text"
                  placeholder="Apellidos"
                  required
                  {...register("last_name")}
                />
                <Input
                  type="text"
                  placeholder="Empresa"
                  required
                  {...register("company")}
                />
                <Input
                  type="text"
                  placeholder="CIF"
                  required
                  {...register("cif")}
                />
                <Input
                  type="text"
                  placeholder="Cargo"
                  required
                  {...register("position")}
                />
                <Input
                  type="text"
                  placeholder="Código Postal"
                  required
                  {...register("postal_code")}
                />
                <Input
                  type="text"
                  placeholder="Provincia"
                  required
                  {...register("state")}
                />
                <Input
                  type="text"
                  placeholder="Teléfono"
                  required
                  {...register("phone_number")}
                />

                <label>
                  <input
                    style={{ marginTop: "20px", marginRight: "5px" }}
                    type="checkbox"
                    required
                  />{" "}
                  Acepto Cesión de Datos &nbsp;&nbsp;
                  <span
                    style={{
                      padding: 3,
                      cursor: "pointer",
                      backgroundColor: "#ccff99",
                    }}
                    onClick={toggleModal}
                  >
                    +Información
                  </span>
                </label>

                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>

              <p tw="mt-6 text-xs text-gray-600 text-center">
                Realizada la inscripción recibirás por correo electrónico un
                enlace para finalizar el proceso de registro
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage tw="rounded-r-lg" imageSrc={illustration} />
        </IllustrationContainer>

        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <h1>Política de Privacidad</h1>
            <br />
            De conformidad con lo establecido en el Reglamento General de Protección de Datos (UE) 2016/679 y en la Ley Orgánica 3/2018 
            de Protección de Datos Personales y Garantía de los Derechos Digitales, los datos de carácter personal serán tratados por la 
            Asociación Cluster Agroalimentario de Navarra (NAGRIFOOD) en su carácter de responsable con la finalidad de garantizar el 
            incremento de la competitividad de las empresas agroalimentarias navarras, y por ende las del sector. Asimismo, le informamos 
            de que puede ejercitar sus derechos de acceso, rectificación, supresión y portabilidad de sus datos, de limitación y oposición 
            a su tratamiento, así como a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de sus datos, cuando 
            procedan, ante la Asociación Cluster Agroalimentario de Navarra (NAGRIFOOD), Parque Tomás Caballero, 2, 6ª planta (31006) 
            Pamplona (Navarra) y en la dirección de correo electrónico comunicacion@nagrifoodcluster.com. Los modelos de estos documentos 
            para ejercitar sus derechos los pueden encontrar en la página web de la Agencia Española de Protección de Datos (www.agpd.es). 
            <br />
            <br />
            Por otro lado, da su consentimiento de forma expresa a la Asociación Cluster Agroalimentario de Navarra (NAGRIFOOD) con domicilio 
            en Parque Tomás Caballero, 2, 6ª planta (31006) Pamplona (Navarra), a acceder al informe individual con las respuestas de la 
            empresa tras realizar la herramienta autodiagnóstico de medición ProgreeNFood. Esta información contenida en el informe individual 
            será confidencial y la única información que se utilizará y compartirá por parte de NAGRIFOOD será a través de medias agregadas de 
            empresas del sector en su conjunto, en ningún caso se compartirá información individual de la empresa. Es decir, NAGRIFOOD 
            explotará de manera agregada los resultados de los informes con el fin de trabajar en  áreas de mejora e identificar buenas 
            prácticas en materia de sostenibilidad.
            <br />
            <br />
            El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones incluidas en el Aviso Legal.
          </div>
        </StyledModal>
      </Content>
    </Container>
  );
};
