import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import backgroundHomeSystem from "images/cabecera_faq.jpg"
import backgroundHomeSystem from "images/header_faqs.webp"

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${backgroundHomeSystem});
`;

const HeroContainer = tw.div`z-20 relative  sm:px-8  mx-auto pt-16 pb-16`;
const TwoColumn = tw.div`pt-24 pb-4 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;

const Heading = styled.h1`
  ${tw`text-xl text-center lg:text-center text-gray-100 leading-none`}
  span {
    ${tw`inline-block`}
  }
`;

const HeadingLite = styled.h1`
  ${tw`text-4xl text-center lg:text-center sm:text-4xl lg:text-4xl xl:text-4xl text-gray-100 leading-none`}
  span {
    ${tw`inline-block`}
  }
`;

export default () => {
  return (
    <Container>

      <HeroContainer >

        <TwoColumn>
          <LeftColumn>
            <Heading>
            <span>FAQs</span>
            </Heading>
            <HeadingLite>
         
            </HeadingLite>
          </LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
