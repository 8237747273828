import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HeroHeaderGenera from "components/pds/HeroHeaderGenera";
import HeroContentGenera from "components/pds/HeroContentGenera";
import FeaturesGenera from "components/pds/FeaturesGenera";

import GetStarted from "components/pds/GetStarted";
import Footer from "components/pds/FooterFiveColumns.js";

export default () => (
  <AnimationRevealPage disabled>

    <FeaturesGenera />


  </AnimationRevealPage>
);