import { atom, useRecoilState } from 'recoil';

const userDataState = atom({
  key: 'user-data-state',
  default: {
    id: 0,
    name: '',
    email: '',
    email_verified_at: '',
    company_id: 0,
    created_at: '',
    updated_at: ''

  },
});

function useUserData() {
  const [userData, setUserData] = useRecoilState(userDataState);

  function updateData(user) {
    console.log('actualizando datos usuario');
    console.log(user);
    setUserData(user);
  }

  return [userData, { updateData }];
}

export default useUserData;