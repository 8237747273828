import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeadingLite,
  SectionHeadingLite2xl,
} from "components/misc/Headings.js";



import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";




const Container = tw.div`relative mb-4`;

const FourColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-2 md:py-1`}
`;



const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Plan = styled.div`
  ${tw`w-full  mt-3 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-white bg-[#f9f9f9] flex flex-col `}

  .big_text {
    ${tw`text-xl`}
  }

  .textContainer {
    ${tw`mt-1 text-center`}
  }

  img {
    ${tw`w-6 h-6`}
  }
`;


const ColumnContainer = tw.div`pt-1 max-w-lg`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-center  mt-4 lg:mt-1 flex-col sm:flex-row`;
const Link = tw.a`rounded w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-1 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#c7aa45] text-gray-100 shadow-lg hocus:bg-green-600 hocus:text-gray-200`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   */

  return (
    <Container>

      <FourColumnContainer>
        
        <LinksContainer>
          <PrimaryLink href="/mide_inicio">
            INICIAR EVALUACIÓN {'>>'}
          </PrimaryLink>
        </LinksContainer>
      </FourColumnContainer>

      <DecoratorBlob />
    </Container>
  );
};
