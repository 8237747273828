import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import axios from "axios";
import configData from "config.json";
import { useForm } from "react-hook-form";
import AuthService from "services/auth.service";
import toast from "react-hot-toast";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Container as ContainerBase } from "components/misc/Layouts";
import illustration from "images/bg_login_form.png";
import { SectionHeading } from "components/misc/Headings.js";
import { motion } from "framer-motion";

// Estilos principales
const Container = tw(
  ContainerBase
)`min-h-screen  text-white font-medium flex justify-center`;
const Content = tw.div` max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow-md sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-6/12 p-4 sm:p-10`;
const MainContent = tw.div` flex flex-col items-center `;
const Heading = tw.h1`text-2xl xl:text-2xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-4`;
const Form = tw.form`mx-auto max-w-lg`;
const Input = tw.input`w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-yellow-600 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-8  w-full  bg-contain bg-center bg-no-repeat`}
`;

const Header = tw(SectionHeading)``;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabContent = tw(
  motion.div
)`mt-6 w-full flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-[#494948]! text-gray-100!`}
  }
`;

/**
 * Definición del Componente
 */
export default ({
  illustrationImageSrc = illustration,
  headingText = "Tus Datos",
  submitButtonText = "Actualizar Datos",
  SubmitButtonIcon = LoginIcon,
}) => {
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    last_name: "",
    position: "",
    postal_code: "",
    phone_number: "",
  });
  const [company, setCompany] = useState({
    name: "",
  });

  const tabsKeys = ["Uno", "Dos"];
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const [evaluationDate, setEvaluationDate] = useState("");
  /**
   * Al cargar la página por primera vez
   */
  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user_token"));
    let baseURL = configData.API_BASE_URL + "started_evaluation/get_current";
    axios
      .get(baseURL, { headers: { Authorization: `Bearer ${userToken}` } })
      .then((response) => {
        let date = null;
        let year = null;
        let month = null;
        let dt = null;

        setUserData(response.data.data.user);
        setCompany(response.data.data.user.company);
        reset(response.data.user);

        if (response.data.data.evaluation) {
          date = new Date(response.data.data.created_at);
          year = date.getFullYear();
          month = date.getMonth() + 1;
          dt = date.getDate();

          if (dt < 10) {
            dt = "0" + dt;
          }
          if (month < 10) {
            month = "0" + month;
          }

          setEvaluationDate(dt + "-" + month + "-" + year);
        }
      })
      .catch((exception) => {
        toast.error("Error de Acceso");
        logout_system();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, company, activeTab, evaluationDate]);

  /**
   * Salida de la parte privada de la aplicación
   */
  const logout_system = () => {
    localStorage.removeItem("user_token");
    window.location.reload();
  };

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    AuthService.update_data_user(data).then((value) => {
      if (value.status === 200) {
        if (value.data) {
          toast.success("Datos del Usuario Actualizados");
        } else {
          toast.error("Error de Acceso.");
        }
      }
    });
  };

  return (
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <HeaderRow>
              <Header></Header>
              <TabsControl>
                <TabControl
                  key={1}
                  active={activeTab === "Uno"}
                  onClick={() => setActiveTab("Uno")}
                >
                  <Heading tw="text-xl">{headingText}</Heading>
                </TabControl>
                <TabControl
                  key={2}
                  active={activeTab === "Dos"}
                  onClick={() => setActiveTab("Dos")}
                >
                  <Heading tw="text-xl">Tus Evaluaciones</Heading>
                </TabControl>
              </TabsControl>
            </HeaderRow>

            <TabContent
              key={1}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === "Uno" ? "current" : "hidden"}
              animate={activeTab === "Uno" ? "current" : "hidden"}
            >
              <FormContainer>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div tw="grid grid-cols-12">
                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Email:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Correo Electrónico"
                        required
                        readOnly
                        defaultValue={userData.email}
                        {...register("email")}
                      />
                    </div>

                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Nombre:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Nombre"
                        required
                        defaultValue={userData.name}
                        {...register("name")}
                      />
                    </div>

                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Apellidos:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Apellidos"
                        required
                        defaultValue={userData.last_name}
                        {...register("last_name")}
                      />
                    </div>

                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Empresa:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Empresa"
                        required
                        readOnly
                        defaultValue={company.name}
                        {...register("company")}
                      />
                    </div>

                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Cargo:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Cargo"
                        required
                        defaultValue={userData.position}
                        {...register("position")}
                      />
                    </div>

                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Código Postal:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Código Postal"
                        required
                        defaultValue={userData.postal_code}
                        {...register("postal_code")}
                      />
                    </div>

                    <div tw="col-span-3">
                      <span tw="text-sm"><strong>Teléfono:</strong></span>
                    </div>
                    <div tw="col-span-9">
                      <Input
                        tw="m-4"
                        type="text"
                        placeholder="Teléfono (opcional)"
                        defaultValue={userData.phone_number}
                        {...register("phone_number")}
                      />
                    </div>
                  </div>

                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </TabContent>
            <TabContent
              key={2}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === "Dos" ? "current" : "hidden"}
              animate={activeTab === "Dos" ? "current" : "hidden"}
            >
              {evaluationDate !== "" && (
                <>
                  <a href="/mide_evaluacion">
                    &#x2705; Evaluación {evaluationDate}
                  </a>
                </>
              )}
            </TabContent>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  );
};
