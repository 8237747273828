import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import Footer from "components/pds/FooterFiveColumns.js";
import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";
import ResultadosEconomico from "components/pds/ResultadosEconomico";

export default () => (
  <>
    <StyledHeaderMenu />
    <ResultadosEconomico />
    <Footer />
  </>
);
