import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ResultadosEvaluacion from "components/pds/ResultadosEvaluacion";
import Footer from "components/pds/FooterFiveColumns.js";
import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";

/**
 * Componente Principal
 */
export default () => {
  return (
    <>
      <StyledHeaderMenu />
      <AnimationRevealPage disabled>
        <ResultadosEvaluacion />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
