import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import Footer from "components/pds/FooterFiveColumns.js";

import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";
import ResultadosMedioAmbiente from "components/pds/ResultadosMedioAmbiente";

/**
 * Definición del Comonponente
 */
export default () => (
  <>
    <StyledHeaderMenu />
    <ResultadosMedioAmbiente />
    <Footer />
  </>
);
