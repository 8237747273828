import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";



const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-10`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`border-2 border-primary-500 md:w-1/2 lg:w-1/3 max-w-sm m-4 bg-gray-100`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw` text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4  text-secondary-100`}
  }
`;

const CardLeft = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center rounded-lg lg:mt-20`}
  .number {
    ${tw`font-black text-6xl`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  heading = "3 Pasos para Preparar tu Diagnóstico",
  subheading = "",
  description = "",
}) => {

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />

        <Column>
          <Card>
            <CardLeft>
              <span className="number">1</span>
              <span className="title">Participa</span>
            </CardLeft>

            <span className="textContainer">

              <p className="description">
                Antes de empezar es importante seleccionar la forma en la que
                desarrollarás el diagnóstico. Puedes elegir entre:
                <br />
                <br />
                <ul>
                  <li>
                    1. Elegir una persona con visión global para que realice la
                    evaluación
                  </li>
                  <li>
                    2. Organizar un equipo con visión global para que, en sesiones
                    de trabajo realicen el diagnóstico
                  </li>
                </ul>
              </p>
            </span>
          </Card>
        </Column>
        <Column>
          <Card>
            <CardLeft>
              <span className="number">2</span>
              <span className="title">Prepara</span>
            </CardLeft>

            <span className="textContainer">

              <p className="description">
                Para realizar el diagnóstico vas a necesitar tener a mano la siguiente información:
                <br />
                <br />
                <ul>
                  <li>
                    1. Lorem Ipsum dolor sit amet
                  </li>
                  <li>
                  2. Lorem Ipsum dolor sit amet
                  </li>
                  <li>
                  3. Lorem Ipsum dolor sit amet
                  </li>
                </ul>
              </p>
            </span>
          </Card>
        </Column>
        <Column>
          <Card>
            <CardLeft>
              <span className="number">3</span>
              <span className="title">Desarrolla</span>
            </CardLeft>

            <span className="textContainer">

              <p className="description">
                Ingresa en la plataforma y registra tu empresa para que disponga del acceso al diagnóstico
                <br />
                <br />
                Sigue todos los pasos que se indican
                <br/><br/>
                Recuerda que podrás dejar preguntas sin responder y luego volver a ellas
              </p>
            </span>
          </Card>
        </Column>
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
