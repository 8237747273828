import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeadingNoBold2xl as HeadingTitle } from "../misc/Headings.js";
import { Container as ContainerBase } from "components/misc/Layouts";

import logoEroski from "images/logo_eroski.jpg";
import logoNagrifood from "images/logo_nagrifood.jpg";
import logoIng from "images/logo_ing.jpg";
import logoMik from "images/logo_mik.jpg";

import backgroundHomeSystem from "images/home.jpg";

const Container = tw(
  ContainerBase
)`my-0 lg:my-0 bg-white text-gray-100  px-8 w-full`;
const Content = tw.div`max-w-screen-xl mx-auto pt-4`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap max-w-screen-xl`;
const Column = tw.div`mt-8 lg:w-1/4 `;
const HeadingInfoContainer = tw.div`flex flex-col items-center text-[#4a4949]`;

const Card = tw.div`lg:mx-1 xl:mx-6 max-w-sm lg:max-w-sm border border-solid border-[#c7aa45] rounded-lg p-3`;

// Boton mas información
const ColumnContainer = tw.div`max-w-screen-xl mt-6`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-center   flex-col sm:flex-row`;
const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#E1E1E1] text-[#4a4949] shadow-lg hocus:bg-red-600 hocus:text-gray-200`;



export default () => {
  const blogPosts = [
    {
      imageSrc: logoEroski,
      category: "SOSTENIBILIDAD SOCIAL",
      title: "",
      url: "https://corporativo.eroski.es/responsabilidad-social-empresarial/medio-ambiente/",
    },
    {
      imageSrc: logoNagrifood,
      category: "SOSTENIBILIDAD ECONOMICA",
      title: "",
      url: "https://nagrifoodcluster.com/actividades/proyectos/",
    },
    {
      imageSrc: logoIng,
      category: "SOSTENIBILIDAD CULTURAL",
      title: "",
      url: "https://www.proyectosnavarra.es/medioambiente/",
    },
    {
      imageSrc: logoMik,
      category: "SOSTENIBILIDAD CULTURAL",
      title: "",
      url: "https://mik.mondragon.edu/es/economia-circular",
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>
            Un Hub de especialistas en materia de sostenibilidad que tienen como
            misión apoyar la transición de las organizaciones del sector
            agroalimentario hacia una competitividad responsable.
          </HeadingTitle>
        </HeadingInfoContainer>

{/*
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <img src={post.imageSrc} alt="empresa"></img>

                <LinksContainer>
                  <PrimaryLink href={post.url} target="_blank">
                    Más Información
                  </PrimaryLink>
                </LinksContainer>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
*/}

      </Content>

    </Container>
  );
};
