import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/icoMail.png";
import backgroundHomeSystem from "images/fondo_mail.jpg";
import { useForm } from "react-hook-form";
import AuthService from "services/auth.service";
import toast from "react-hot-toast";

// Estilos Página
const ContainerEmail = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover mt-4 text-white `}
  background-image: url(${backgroundHomeSystem});
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-5/12 flex-shrink-0 h-96 md:h-auto mb-16 pb-6 flex flex-col items-center`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const TextContent = tw.div`lg:py-8 text-center  md:text-left mb-6 ml-10 mr-10`;
const Subheading = tw(SubheadingBase)`text-center md:text-left text-[#c7aa45]`;
const Heading = tw(
  SectionHeading
)`mt-4 font-bold text-left text-3xl sm:text-4xl lg:text-3xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white`;
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`bg-transparent mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-[#c7aa45]`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;
const SubmitButton = tw(
  PrimaryButtonBase
)`inline-block mt-8 bg-[#c7aa45] hocus:bg-[#41AD2B]`;

/**
 * Componente Principal
 */
export default ({
  subheading = "CONTÁCTANOS",
  subheadingHelp = "AYUDA",
  heading = (
    <>
      ¿Quieres <span tw="text-[#c7aa45]">más información</span>
      sobre el proyecto y lo que puede hacer por ti?
    </>
  ),
  descriptionHelp = (
    <>
      ¿Necesitas ayuda? Si tienes cualquier duda, puedes enviarnos un correo electrónico a <strong><a href="mailto:cluster@nagrifoodcluster.com">cluster@nagrifoodcluster.com</a></strong> 
      <br />
      <br />
      También puedes consultar las <strong><a href="/faqs">FAQs</a></strong> para obtener más información sobre nuestra plataforma.
    </>
  ),
  description = "Descubre el grado de sostenibilidad económica, social y medioambiental de tu organización agroalimentaria e identifica áreas de mejora sobre las que trabajar a corto, medio y largo plazo.",
  submitButtonText = "ENVIAR",
  textOnLeft = true
}) => {

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    AuthService.process_contact_form(data).then((value) => {
      if (value.status == 200) {
        if (value.data) {
          toast.success("Datos del Formulario de Contacto enviados");
        } else {
          toast.error("Error de Acceso.");
        }
      }
    });
  };

  return (
    <ContainerEmail>
      <TwoColumn>
        <ImageColumn>
          <TextContent>
            <Subheading>{subheadingHelp}</Subheading>
            {descriptionHelp}
          </TextContent>
          <div>
            <img width="200" src={EmailIllustrationSrc} />
          </div>
        </ImageColumn>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input 
                type="text" 
                placeholder="Nombre"
                required
                {...register("name")}
                />
              <Input 
                type="text"
                placeholder="Apellidos"
                required
                {...register("last_name")}
                />

              <Input 
                type="email" 
                name="email_company" 
                placeholder="Email Empresa" 
                {...register("email_company")}
                />

              <Textarea 
                name="content" 
                placeholder="Mensaje" 
                {...register("content")}
                />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </ContainerEmail>
  );
};
