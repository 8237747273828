import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import axios from "axios";
import configData from "config.json";
import EvaluationService from "services/evaluation.service";
import toast from "react-hot-toast";

// Iconos
import IcoInfo from "../../images/IcoInfo.png";
import IcoCaracterizacionColor from "../../images/IcoCaracterizacionColor.png";
import IcoEconomicaColor from "../../images/IcoEconomicaColor.png";
import IcoEconomicaGris from "../../images/IcoEconomicaGris.png";
import IcoSocialColor from "../../images/IcoSocialColor.png";
import IcoSocialGris from "../../images/IcoSocialGris.png";
import IcoMedioambientalColor from "../../images/IcoMedioambientalColor.png";
import IcoMedioambientalGris from "../../images/IcoMedioambientalGris.png";

// Estilos del Interfaz
let Intro = styled.div`
  ${tw`md:w-1/2 lg:w-4/5 bg-[#494948 ] p-2 pl-4 text-center  rounded-lg text-xl text-white`}
`;
const Question = styled.div`
  ${tw`md:w-1/2 lg:w-4/5 bg-[#f9f9f9] p-2 pl-4 mt-0   rounded-lg text-base text-gray-700`}
`;
const InfoQuestion = styled.div`
  ${tw`md:w-1/2 lg:w-4/5 bg-red-600 p-4 pl-8 mt-8   rounded-lg text-xl text-white`}
`;
const InfoSelection = styled.div`
  ${tw`md:w-1/2 lg:w-4/5  p-2 pl-4 mt-2   rounded-lg text-sm font-normal text-red-700`}
`;
const AnswersContainer = styled.div`
  ${tw`md:w-1/2 lg:w-4/5 bg-white p-4 pl-4 mt-0   rounded-lg text-base  text-gray-700`}
`;
const QuestionPager = styled.div`
  ${tw`sm:w-full md:w-full lg:w-4/5 bg-[#f9f9f9] p-4 pl-4 mt-0   rounded-lg text-lg text-gray-700`}
`;
const Container = tw.div`relative`;
const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-5 md:py-10 `}
`;
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/4 px-4 flex`}
`;
const Card = styled.div`
  ${tw`flex 2xl:flex-col xl:flex-col lg:flex-col md:flex-col flex-row mx-auto w-full items-center px-6 py-2  rounded-lg mt-0`}
  .imageContainer {
    ${tw`border-0 border-yellow-600 text-center rounded-full p-2 flex-shrink-0 relative`}
    img {
      ${tw`w-6 h-6`}
    }
  }
  .textContainer {
    ${tw`mt-2 text-center  p-1`}
  }
  .textContainerGray {
    ${tw`mt-2 text-center p-1`}
  }
  .title {
    ${tw`mt-2  text-base leading-none text-gray-700 `}
  }
  .title_gray {
    ${tw`mt-2  text-base leading-none text-gray-400 `}
  }
  .horizontal {
    ${tw`border-yellow-600 h-1`}
  }
  .progress_gray_color {
    ${tw`bg-[#494948]`}
  }
  .progress_orange_color {
    ${tw`bg-[#F0A000]`}
  }
  .progress_blue_color {
    ${tw`bg-[#867796]`}
  }
  .progress_green_color {
    ${tw`bg-[#41AD2B]`}
  }
`;

// Boton mas información
const Link = tw.a` w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-normal border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#424445] text-gray-100 shadow-lg hocus:bg-gray-600 hocus:text-gray-200`;

/**
 * Definición del Componente
 */
export default () => {
  const [showResults, setShowResults] = React.useState(false);
  const [startedEvaluation, setStartedEvaluation] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [numQuestions, setNumQuestions] = useState(0);
  const [question, setQuestion] = useState({});
  const [titlePillar, setTitlePillar] = useState("");
  const [idPillar, setIdPillar] = useState(0);
  const [bgOp1, setBgOp1] = useState("#f9f9f9");
  const [bgOp2, setBgOp2] = useState("#f9f9f9");
  const [bgOp3, setBgOp3] = useState("#f9f9f9");
  const [bgOp4, setBgOp4] = useState("#f9f9f9");
  const [questionsCaracterizationPercent, SetQuestionsCaracterizationPercent] =
    useState("0%");
  const [questionsEconomicPercent, SetQuestionsEconomicPercent] =
    useState("0%");
  const [questionsSocialPercent, SetQuestionsSocialPercent] = useState("0%");
  const [questionsEnvironmentalPercent, SetQuestionsEnvironmentalPercent] =
    useState("0%");
  const [currentSelectValue, setCurrentSelectValue] = useState("");
  const [level1ActivityValue, setLevel1ActivityValue] = useState("");
  const [selectedOption , setSelectedOption] = useState(false);
  const navigate = useNavigate();

  const level2Activities = {
    "01.- Agricultura, ganadería, caza y servicios relacionados con las mismas.": [
      "011.- Cultivos no perennes.",
      "012.- Cultivos perennes.",
      "013.- Propagación de plantas.",
      "015.- Producción agrícola combinada con la producción ganadera.",
      "016.- Actividades de apoyo a la agricultura, a la ganadería y de preparación posterior a la cosecha.",
      "014.- Producción ganadera.",
      "017.- Caza, captura de animales y servicios relacionados con las mismas.",
      ],
    "02.- Silvicultura y explotación forestal.": [
      "021.- Silvicultura y otras actividades forestales.",
      "022.- Explotación de la madera.",
      "023.- Recolección de productos silvestres, excepto madera.",
      "024.- Servicios de apoyo a la silvicultura."
    ],
    "03.- Pesca y acuicultura.": [
      "031.- Pesca.",
      "032.- Acuicultura."
    ],
    "10.- Industria de la alimentación.": [
      "101.- Procesado y conservación de carne y elaboración de productos cárnicos.",
      "102.- Procesado y conservación de pescados, crustáceos y moluscos.",
      "103.- Procesado y conservación de frutas y hortaliza.",
      "104.- Fabricación de aceites y grasas vegetales y animales.",
      "105.- Fabricación de productos lácteos.",
      "106.- Fabricación de productos de molinería, almidones y productos amiláceos.",
      "107.- Fabricación de productos de panadería y pastas alimenticias.",
      "108.- Fabricación de otros productos alimenticios.",
      "109.- Fabricación de productos para la alimentación animal."
    ],
    "11.- Fabricación de bebidas.": [
      "110.- Fabricación de bebidas."
    ]
  };

  /**
   * Al cargar la página por primera vez
   */
  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user_token"));
    let baseURL = configData.API_BASE_URL + "started_evaluation/get_current";
    axios
      .get(baseURL, { headers: { Authorization: `Bearer ${userToken}` } })
      .then((response) => {
        setStartedEvaluation(response.data.data);
        let d = response.data.data;

        console.log(d);

        // Actualización del interface
        update_gui(d);
      }).catch((exeption) => {
        toast.error("Conectando ..");
        //logout_system();
      });
  }, [bgOp1, bgOp2, bgOp3, bgOp4]); // eslint-disable-line react-hooks/exhaustive-deps

  const set_bgopts_by_default = () => {
    setBgOp1("#f9f9f9");
    setBgOp2("#f9f9f9");
    setBgOp3("#f9f9f9");
    setBgOp4("#f9f9f9");
  };

  /**
   * Salida de la parte privada de la aplicación
   */
    const logout_system = () => {
      //localStorage.removeItem("user_token");
      window.location.reload();
    };

  /**
   * Actualización del interfaz gráfico
   */
  const update_gui = (d) => {
    // Si estamos en la fase de caracterizacion
    if (d.pillar.id == 1) {
      // Si el pilar estar finalizado mostramos los resultados
      if (d.finished_characterization_pillar == 1) {
        navigate("/resultados_caracterizacion");
      }

      setTitlePillar(d.pillar.title);
      setIdPillar(d.pillar.id);
      let characterization_questions =
        d.evaluation.characterization.characterization_questions;

      setQuestions(characterization_questions);
      setNumQuestions(characterization_questions.length);

      let tmp_calc = parseInt(
        (parseInt(d.selected_characterization_questions.length) /
          parseInt(characterization_questions.length)) *
          100
      );
      SetQuestionsCaracterizationPercent(tmp_calc + "%");
      setQuestion(characterization_questions[d.current_question_position]);

      // Marcamos la respuesta seleccionada
      let is_selected_option = false;
      setSelectedOption(false);
      if (
        characterization_questions[d.current_question_position].type == "OPCION"
      ) {
        for (let i = 0; i < d.selected_characterization_questions.length; i++) {
          if (
            d.selected_characterization_questions[i]
              .characterization_question_id ==
            characterization_questions[d.current_question_position].id
          ) {
            is_selected_option = true;
            setColorSelectedOption(
              d.selected_characterization_questions[i].option_selected
            );

            setSelectedOption(true);
          }
        }

        if (!is_selected_option) {
          set_bgopts_by_default();
        }
      } else {
        let options_arr =
          characterization_questions[d.current_question_position].list.split(
            ";"
          );

        for (let i = 0; i < d.selected_characterization_questions.length; i++) {
          if (
            d.selected_characterization_questions[i]
              .characterization_question_id ==
            characterization_questions[d.current_question_position].id
          ) {
            is_selected_option = true;
            setCurrentSelectValue(
              d.selected_characterization_questions[i].option_selected
            );

            setSelectedOption(true);

          }
        }

        if (!is_selected_option) {
          if (characterization_questions[d.current_question_position].id == 2) {
            selectListOption(
              characterization_questions[d.current_question_position],
              options_arr[0]
            );
          }
          if (characterization_questions[d.current_question_position].id == 3) {
            let options_select = level2Activities[level1ActivityValue];
            selectListOption(
              characterization_questions[d.current_question_position],
              options_select[0]
            );
          }

        }
      }
    } else {
      navigate("/mide_evaluacion_pilar");
    }
  };

  /**
   *
   * Mostrar información ayuda
   */
  const toggleInfo = () => {
    setShowResults(!showResults);
  };

  /**
   * Helper colores seleccion
   */
  const setColorSelectedOption = (option) => {
    if (option == "op_1") {
      setBgOp1("#CBD5E0");
      setBgOp2("#f9f9f9");
      setBgOp3("#f9f9f9");
      setBgOp4("#f9f9f9");
    } else if (option == "op_2") {
      setBgOp1("#f9f9f9");
      setBgOp2("#CBD5E0");
      setBgOp3("#f9f9f9");
      setBgOp4("#f9f9f9");
    } else if (option == "op_3") {
      setBgOp1("#f9f9f9");
      setBgOp2("#f9f9f9");
      setBgOp3("#CBD5E0");
      setBgOp4("#f9f9f9");
    } else if (option == "op_4") {
      setBgOp1("#f9f9f9");
      setBgOp2("#f9f9f9");
      setBgOp3("#f9f9f9");
      setBgOp4("#CBD5E0");
    }
  };

  /**
   * Selección de un valor de una lista
   */
  const selectListOption = (question, option) => {
    setCurrentSelectValue(option);
    if (question.id == 2) {
      setLevel1ActivityValue(option);
    }

    let selected_characterization_question = {
      started_evaluation_id: startedEvaluation.id,
      characterization_question_id: question.id,
      type_selected: question.type,
      option_selected: option,
      value_selected: question.list,
    };

    EvaluationService.add_selected_characterization_question(
      selected_characterization_question
    )
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          setSelectedOption(true);
          //toast.success("Seleccionada Opción");          
        } else {
          toast.error("Error de Acceso en Adición.");
        }
      })
      .catch((exeption) => {
        toast.error("Conectando ...");
        //logout_system();
      });
  };

  /**
   * Selección de una opción simple de la pregunta
   */
  const selectSimpleOption = (question, option) => {
    setColorSelectedOption(option);

    let selected_characterization_question = {
      started_evaluation_id: startedEvaluation.id,
      characterization_question_id: question.id,
      type_selected: question.type,
      option_selected: option,
      value_selected: question.list,
    };

    EvaluationService.add_selected_characterization_question(
      selected_characterization_question
    )
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          setSelectedOption(true);
          //toast.success("Seleccionada Opción");
        } else {
          toast.error("Error de Acceso en Adición.");
        }
      })
      .catch((exeption) => {
        toast.error("Conectando ...");
        //logout_system();
      });
  };

  /**
   * Pregunta siguiente
   */
  const getNextQuestion = () => {
    if (!selectedOption) {
      toast.error("Error: No ha realizado ninguna selección");
      return;
    } else {
      setSelectedOption(false);
    }

    if (startedEvaluation.current_question_position < numQuestions - 1) {
      let updated_started_evaluation = {
        id: startedEvaluation.id,
        current_question_position:
          startedEvaluation.current_question_position + 1,
      };

      EvaluationService.update_started_evaluation(updated_started_evaluation)
        .then((value) => {
          if (value.data.success) {
            setStartedEvaluation(value.data.data);
            setShowResults(false);
            update_gui(value.data.data);

            //window.location.reload();
          } else {
            toast.error("Error de Acceso en actualización");
          }
        })
        .catch((exception) => {
          toast.error("Conectando ...");
          //logout_system();
        });
    } else {
      let length_sel_questions =
        startedEvaluation.selected_characterization_questions.length;
      let length_char_questions =
        startedEvaluation.evaluation.characterization.characterization_questions
          .length;

      if (length_sel_questions < length_char_questions) {
        toast.error(
          "ERROR. No todas las preguntas están contestadas. Por favor, revise sus respuestas."
        );
      } else {
        if (startedEvaluation.pillar.id == 1) {
          // Generamos las preguntas para esta evaluacion
          EvaluationService.generate_started_evaluation_questions(
            startedEvaluation
          )
            .then((value) => {
              if (value.data.success) {
                setStartedEvaluation(value.data.data);
                update_gui(value.data.data);
              } else {
                toast.error("Error de Acceso en actualización");
              }
            })
            .catch((exception) => {
              toast.error("Conectando ...");
              //logout_system();
            });
        } else {
          console.log("error");
        }
      }
    }
  };

  /**
   * Pregunta anterior
   */
  const getPreviousQuestion = () => {
    if (!selectedOption) {
      toast.error("Error: No ha realizado ninguna selección");
      return;
    } else {
      setSelectedOption(true);
    }

    if (startedEvaluation.current_question_position > 0) {
      let updated_started_evaluation = {
        id: startedEvaluation.id,
        current_question_position:
          startedEvaluation.current_question_position - 1,
      };

      EvaluationService.update_started_evaluation(updated_started_evaluation)
        .then((value) => {
          if (value.data.success) {
            setStartedEvaluation(value.data.data);
            update_gui(value.data.data);

            //window.location.reload();
          } else {
            toast.error("Error de Actualización");
          }
        })
        .catch((exception) => {
          toast.error("Conectando ...");
          //logout_system();
        });
    } else {
      toast.error("Nos encontramos en la primera pregunta");
    }
  };

  /**
   * Navegar pregunta específica por posicion
   */
  const goToQuestionByPosition = (question_position) => {
    if (!selectedOption) {
      toast.error("Error: No ha realizado ninguna selección");
      return;
    }

    let updated_started_evaluation = {
      id: startedEvaluation.id,
      current_question_position: question_position,
    };

    EvaluationService.update_started_evaluation(updated_started_evaluation)
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          update_gui(value.data.data);
        } else {
          toast.error("Error de Actualización");
        }
      })
      .catch((exception) => {
        toast.error("Conectando ...");
        // logout_system();
      });
  };

  const gen_card = (card) => {
    const get_progressbar = (card) => {
      if (card.pilar_id == 1) {
        return (
          <div tw="w-full  rounded-full h-1 bg-gray-400">
            <div
              tw=" h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: questionsCaracterizationPercent }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 2) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              style={{ width: questionsEconomicPercent }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 3) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              style={{ width: questionsSocialPercent }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 4) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              style={{ width: questionsEnvironmentalPercent }}
            ></div>
          </div>
        );
      }
    };

    if (card.pilar_id == idPillar) {
      return (
        <Card>
          <span className="imageContainer">
            <img src={card.image_src_active} alt="" />
            
          </span>

          {get_progressbar(card)}

          <span className="textContainer">
            <span className={card.class_title_active}>{card.title}</span>
          </span>
        </Card>
      );
    } else {
      return (
        <Card>
          <span className="imageContainer">
            <img src={card.image_src_inactive} alt="" />
          </span>

          {get_progressbar(card)}

          <span className="textContainerGray">
            <span className={card.class_title_inactive}>{card.title}</span>
          </span>
        </Card>
      );
    }
  };

  /**
   * Generación de un select
   */
  const generate_question_select = (question) => {
    console.log("valor actual select -> ");
    console.log(currentSelectValue);
    console.log(question);
    console.log("valor actual level1ActivityValue -> ");
    console.log(level1ActivityValue);

    let options_select = question.list.split(";");

    if (question.id == 3) {
      if (level1ActivityValue) {
        options_select = level2Activities[level1ActivityValue];
        console.log("options_select cambiado -> ");
        console.log(options_select);
        
      }
    }

    return (
      <select
        value={currentSelectValue}
        onChange={(e) => {
          selectListOption(question, e.target.value);
        }}
        name="q{question.id}"
        id="q{question.id}"
        tw="text-white border-gray-500 p-2 bg-[#494948 ]  text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full"
      >
        
        {options_select.map((os, i) => (
          <option key={os} value={os}>
            {os}
          </option>
        ))}

      </select>
    );
  };

  const cards = [
    {
      pilar_id: 1,
      image_src_active: IcoCaracterizacionColor,
      image_src_inactive: IcoCaracterizacionColor,
      title: "CARACTERIZACIÓN",
      class_title_active: "title",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_gray_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 2,
      image_src_active: IcoEconomicaColor,
      image_src_inactive: IcoEconomicaGris,
      title: "Pilar ECONÓMICO",
      class_title_active: "title",
      class_title_inactive: "title_gray",
      class_progress_foreground: "bg-gray-700",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 3,
      image_src_active: IcoSocialColor,
      image_src_inactive: IcoSocialGris,
      title: "Pilar SOCIAL",
      class_title_active: "title",
      class_title_inactive: "title_gray",
      class_progress_foreground: "bg-gray-700",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 4,
      image_src_active: IcoMedioambientalColor,
      image_src_inactive: IcoMedioambientalGris,
      title: "Pilar MEDIOAMBIENTAL",
      class_title_active: "title",
      class_title_inactive: "title_gray",
      class_progress_foreground: "bg-gray-700",
      class_progress_background: "bg-gray-200",
    },
  ];

  return (
    <Container>
      <FourColumnContainer tw="mt-10 sm:mt-0">
        {cards.map((card, i) => (
          <Column key={i}>{gen_card(card, startedEvaluation)}</Column>
        ))}

        <Intro tw="mt-6">{titlePillar}</Intro>

        <Question>
          <div tw="grid grid-cols-12">
            <div tw="col-span-2 flex justify-center items-center">
              <span tw="text-4xl">
                {startedEvaluation.current_question_position + 1}
              </span>{" "}
              / {numQuestions}
            </div>
            <div tw="col-span-9 flex justify-center items-center">
              {question.content}
            </div>
            <div tw="col-span-1 flex justify-center items-center p-4">
              {question.help && (
                <a href="#">
                  <img src={IcoInfo} alt="" onClick={toggleInfo} />
                </a>
              )}
            </div>
          </div>
        </Question>

        {showResults && <InfoQuestion>{question.help}</InfoQuestion>}

        <InfoSelection>
          <div tw="grid grid-cols-1">
          {question.type == "LIST" && (
            <span>Selecciona <span tw="font-bold">LA OPCIÓN</span> del siguiente desplegable que más se ajuste a tu empresa</span>
          )}
          {question.type == "OPCION" && (
            <span>Selecciona <span tw="font-bold">LA OPCIÓN</span> que más se ajuste a tu empresa</span>
          )}
          </div>
        </InfoSelection>

        <AnswersContainer>
          {question.type == "LIST" && (
            <>
            <div>{generate_question_select(question)}</div>
            <br/><br/>
            </>

          )}

          <div tw="grid grid-cols-2 gap-2">
            {question.op_1 && (
              <div
                key="q_op1"
                id="q_op1"
                style={{ backgroundColor: bgOp1 }}
                tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6  rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                onClick={(e) => selectSimpleOption(question, "op_1")}
              >
                <div tw="col-span-1 bg-[#494948] rounded-md text-center text-3xl text-white flex justify-center items-center">
                  <a href="#">A</a>
                </div>
                <div tw="col-span-5  pl-4 text-left flex items-center">
                  <a href="#">{question.op_1}</a>
                </div>
              </div>
            )}

            {question.op_2 && (
              <div
                key="q_op2"
                id="q_op2"
                style={{ backgroundColor: bgOp2 }}
                tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                onClick={(e) => selectSimpleOption(question, "op_2")}
              >
                <div tw="col-span-1 bg-[#494948] rounded-md text-center text-3xl text-white flex justify-center items-center">
                  <a href="#">B</a>
                </div>
                <div tw="col-span-5  pl-4 text-left flex items-center">
                  <a href="#">{question.op_2}</a>
                </div>
              </div>
            )}

            {question.op_3 && (
              <div
                key="q_op3"
                id="q_op3"
                style={{ backgroundColor: bgOp3 }}
                tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                onClick={(e) => selectSimpleOption(question, "op_3")}
              >
                <div tw="col-span-1 bg-[#494948] rounded-md text-center text-3xl text-white flex justify-center items-center">
                  <a href="#">C</a>
                </div>
                <div tw="col-span-5 pl-4 text-left flex items-center">
                  <a href="#">{question.op_3}</a>
                </div>
              </div>
            )}

            {question.op_4 && (
              <div
                key="q_op4"
                id="q_op4"
                style={{ backgroundColor: bgOp4 }}
                tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                onClick={(e) => selectSimpleOption(question, "op_4")}
              >
                <div tw="col-span-1 bg-[#494948] rounded-md text-center text-3xl text-white flex justify-center items-center">
                  <a href="#">D</a>
                </div>
                <div tw="col-span-5 pl-4 text-left flex items-center">
                  <a href="#">{question.op_4}</a>
                </div>
              </div>
            )}
          </div>
        </AnswersContainer>

        <QuestionPager tw="p-4 mb-16">

          <div tw="grid grid-cols-12">
            
            <div tw="col-span-2">
              <PrimaryLink tw="rounded" href="#" onClick={getPreviousQuestion}>
                {"<<"}Anterior
              </PrimaryLink>
            </div>

            <div tw="md:col-span-8 invisible md:visible text-center">
              {questions.map((cq, i) => (
                <a
                  tw="text-2xl"
                  href="#"
                  onClick={() => goToQuestionByPosition(i)}
                >
                  {startedEvaluation.current_question_position == i && (
                    <span tw="p-2 bg-gray-400">{i + 1}</span>
                  )}
                  {startedEvaluation.current_question_position != i && (
                    <span tw="p-2">{i + 1}</span>
                  )}
                </a>
              ))}
            </div>
            <div tw="lg:hidden sm:hidden xl:invisible sm:col-span-5 md:hidden col-span-5"></div>
            <div tw="col-span-2">
              <PrimaryLink tw="rounded" href="#" onClick={getNextQuestion}>
                Siguiente{">>"}
              </PrimaryLink>
            </div>
          </div>

        </QuestionPager>
      </FourColumnContainer>
      <br/><br/><br/>
    </Container>
  );
};
