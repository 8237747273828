import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";

//import AgencyLandingPage from "demos/AgencyLandingPage.js";

// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import HomeLandingPage from "pages/HomeLandingPage";
import ExpertSystemPage from "pages/ExpertSystemPage";
import CompanyPage from "pages/CompanyPage";
import FaqPage from "pages/FaqPage";
import ComponentRenderer from "ComponentRenderer.js";

import ThankYouPage from "ThankYouPage.js";
import QuienesSomosPage from "pages/QuienesSomosPage";
import KnowledgeHubPage from "pages/KnowledgeHubPage";
import ExpertMidePage from "pages/ExpertMidePage";
import ExpertValidaPage from "pages/ExpertValidaPage";
import ExpertGeneraPage from "pages/ExpertGeneraPage";
import ExpertActuaPage from "pages/ExpertActuaPage";

import InicioMidePage from "pages/InicioMidePage";
import IntroMidePage from "pages/IntroMidePage";
import EvaluacionMidePage from "pages/EvaluacionMidePage";
import EvaluacionMidePilarPage from "pages/EvaluacionMidePilarPage";

import ResultsCharacterizationPage from "pages/ResultsCharacterizationPage";
import ResultsEconomicPage from "pages/ResultsEconomicPage";
import ResultsEnvironmentPage from "pages/ResultsEnvironmentPage";
import ResultsSocialPage from "pages/ResultsSocialPage";
import ResultsEvaluationPage from "pages/ResultsEvaluationPage";

import ResultsEvaluationReportPage from "pages/ResultsEvaluationReportPage";
import ResultsEvaluationReportPageAdmin from "pages/ResultsEvaluationReportPageAdmin";

import MiCuentaPage from "pages/MiCuentaPage";
import LoginPage from "pages/LoginPage";
import RegisterPage from "pages/RegisterPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import PasswordChangedPage from "pages/PasswordChangedPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { RecoilRoot } from "recoil";
import { Toaster } from 'react-hot-toast';
import ProtectedRoute from "components/app/ProtectedRoute";


export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <>
      <RecoilRoot>
        <Toaster />
        <GlobalStyles />
        <Router>
          <Routes>
            <Route
              path="/components/:type/:subtype/:name"
              element={<ComponentRenderer />}
            />
            <Route
              path="/components/:type/:name"
              element={<ComponentRenderer />}
            />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/" element={<HomeLandingPage />} />
            <Route path="/expert_system" element={<ExpertSystemPage />} />
            <Route path="/quienes_somos" element={<QuienesSomosPage />} />
            <Route path="/empresa" element={<CompanyPage />} />
            <Route path="/faqs" element={<FaqPage />} />
            <Route path="/knowledge_hub" element={<KnowledgeHubPage />} />
            <Route path="/mide" element={<ExpertMidePage />} />
            <Route path="/valida" element={<ExpertValidaPage />} />
            <Route path="/test" element={<ExpertGeneraPage />} />
            <Route path="/actua" element={<ExpertActuaPage />} />
            
            <Route path="/mide_inicio" element={<InicioMidePage />} />
            <Route path="/mide_intro" element={<IntroMidePage />} />

            <Route path="/mide_evaluacion" element={<ProtectedRoute><EvaluacionMidePage /></ProtectedRoute>} />

            <Route path="/mide_evaluacion_pilar" element={<ProtectedRoute><EvaluacionMidePilarPage /></ProtectedRoute>} />

            <Route path="/resultados_caracterizacion" element={<ProtectedRoute><ResultsCharacterizationPage /></ProtectedRoute>} />
            <Route path="/resultados_economicos" element={<ProtectedRoute><ResultsEconomicPage /></ProtectedRoute>} />
            <Route path="/resultados_sociales" element={<ProtectedRoute><ResultsSocialPage /></ProtectedRoute>} />
            <Route path="/resultados_medio_ambiente" element={<ProtectedRoute><ResultsEnvironmentPage /></ProtectedRoute>} />

            <Route path="/resultados_evaluacion" element={<ProtectedRoute><ResultsEvaluationPage /></ProtectedRoute>} />

            <Route path="/resultados_evaluacion_informe" element={<ProtectedRoute><ResultsEvaluationReportPage /></ProtectedRoute>} />
            <Route path="/resultados_evaluacion_informe_admin/:user_id" element={<ResultsEvaluationReportPageAdmin />} />

            <Route path="/mi_cuenta" element={<ProtectedRoute><MiCuentaPage /></ProtectedRoute>} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/registro" element={<RegisterPage />} />
            <Route path="/forgot_password" element={<ForgotPasswordPage />} />
            <Route path="/password_changed" element={<PasswordChangedPage />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </>
  );
}
