import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useLocation } from "react-router-dom";

import Header, {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-2 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-[#41AD2B] hover:text-[#41AD2B] text-black`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-[#41AD2B]`}
  }
`;

const ColumnContainer = tw.div`pt-3 max-w-lg`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-start  mt-4 lg:mt-4 flex-col sm:flex-row`;
const Link = tw.a`rounded w-full sm:w-auto text-sm sm:text-base  lg:px-2 lg:py-1  border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#424445] hocus:bg-gray-600 text-gray-100 shadow-lg  hocus:text-gray-200`;

// mide_intro es la pagina de presentacion
export default () => {
  const location = useLocation();
  console.log(location.pathname);

  const logout_system = () => {
    localStorage.removeItem("user_token");
    window.location.reload();
  };

  let user_token = localStorage.getItem("user_token");

  if (user_token) {
    user_token = JSON.parse(user_token);
  }

  const navLinks = [
    <NavLinks key={1}>
      {
        location.pathname === '/' && (
          <NavLink tw="border-[#41AD2B] text-[#41AD2B]" href="/">Inicio</NavLink>
        )
      }
      {
        location.pathname !== '/' && (
          <NavLink href="/">Inicio</NavLink>
        )
      }

      {
        (location.pathname === '/mide_inicio' || 
        location.pathname === '/mide_evaluacion_pilar' ||
        location.pathname === '/resultados_caracterizacion' ||
        location.pathname === '/resultados_economicos' ||
        location.pathname === '/resultados_sociales' ||
        location.pathname === '/resultados_medio_ambiente' ||
        location.pathname === '/resultados_evaluacion'
        ) && (
          <NavLink tw="border-[#41AD2B] text-[#41AD2B]" href="/mide_inicio">Mide tu impacto</NavLink>
        )
      }
      {
        (location.pathname !== '/mide_inicio' && 
        location.pathname !== '/mide_evaluacion_pilar' &&
        location.pathname !== '/resultados_caracterizacion' &&
        location.pathname !== '/resultados_economicos' &&
        location.pathname !== '/resultados_sociales' &&
        location.pathname !== '/resultados_medio_ambiente' &&
        location.pathname !== '/resultados_evaluacion'
        ) && (
          <NavLink href="/mide_inicio">Mide tu impacto</NavLink>
        )
      }
      
      {
        location.pathname === '/quienes_somos' && (
          <NavLink tw="border-[#41AD2B] text-[#41AD2B]" href="/quienes_somos">Quiénes Somos</NavLink>
        )
      }
      {
        location.pathname !== '/quienes_somos' && (
          <NavLink href="/quienes_somos">Quiénes Somos</NavLink>
        )
      }
      
      {
        location.pathname === '/faqs' && (
          <NavLink tw="border-[#41AD2B] text-[#41AD2B]" href="/faqs">FAQ's</NavLink>
        )
      }
      {
        location.pathname !== '/faqs' && (
          <NavLink href="/faqs">FAQ's</NavLink>
        )
      }

      {(user_token && location.pathname === '/mi_cuenta') && <NavLink tw="border-[#41AD2B] text-[#41AD2B]" href="/mi_cuenta">Mi Cuenta</NavLink>}
      {(user_token && location.pathname !== '/mi_cuenta') && <NavLink href="/mi_cuenta">Mi Cuenta</NavLink>}

      {!user_token && (
        <PrimaryLink tw="text-sm bg-[#494948] hocus:bg-[#41AD2B]" href="/registro">
          REGÍSTRATE
        </PrimaryLink>
      )}
      {user_token && (
        <PrimaryLink
          tw="ml-2 bg-[#494948] hocus:bg-[#41AD2B] text-sm"
          href="#"
          onClick={logout_system}
        >
          SALIR
        </PrimaryLink>
      )}
      {!user_token && (
        <PrimaryLink
          tw="ml-2 bg-[#494948] hocus:bg-[#41AD2B] text-sm"
          href="/login"
        >
          INICIAR SESIÓN
        </PrimaryLink>
      )}
    </NavLinks>,
  ];

  return <StyledHeader links={navLinks} />;
};
