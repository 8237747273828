import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/pds/BackgroundAsImageWithCenteredContent";

import ThreeColWithSideImage from "components/pds/ThreeColWithSideImage";
import GetStarted from "components/pds/GetStarted";
import Footer from "components/pds/FooterFiveColumns.js";
import FourCards from "components/pds/FourCardsExpertSystem";

import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";

export default () => (
  <>
    <StyledHeaderMenu />
    <AnimationRevealPage disabled>
      <Hero />

      <FourCards></FourCards>
      <ThreeColWithSideImage />

 
      <Footer />
    </AnimationRevealPage>
  </>
);
