import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/pds/BackgroundAsImageWithCenteredContentCompany";
import HeroCompany from "components/pds/BackgroundAsImageCompany";
import FeaturesCompany from "components/pds/FeaturesCompany";
import GetStarted from "components/pds/GetStarted";
import Footer from "components/pds/FooterFiveColumns.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <FeaturesCompany />
    <HeroCompany />
    <GetStarted />
    <Footer />
  </AnimationRevealPage>
);
