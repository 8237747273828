import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HeroHeaderMide from "components/pds/HeroHeaderMide";
import HeroContentMide from "components/pds/HeroContentMide";

import FeaturesMide from "components/pds/FeaturesMide";
import GetStarted from "components/pds/GetStarted";
import Footer from "components/pds/FooterFiveColumns.js";

import StyledHeaderMenu from "components/misc/StyledHeaderMenu.js";

export default () => (
  <>
    <StyledHeaderMenu />
  <AnimationRevealPage disabled>
    <HeroHeaderMide />
    <HeroContentMide />
    <FeaturesMide />
    <GetStarted />
    <Footer />
  </AnimationRevealPage>
  </>

);