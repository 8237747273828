import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/pds/HeroKnowledgeHub";

import TwoColWithSideImageKnowledgeHub from "components/pds/TwoColWithSideImageKnowledgeHub";

import Footer from "components/pds/FooterFiveColumns.js";

import FeaturesKnowledgeHub from "components/pds/FeaturesKnowledgeHub";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <FeaturesKnowledgeHub />


    <TwoColWithSideImageKnowledgeHub />


    <Footer />
  </AnimationRevealPage>
);