import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundHomeSystem from "images/sustainable.jpg";

import ImgSrc from "images/se_genera.jpg";

const Container = styled.div`
  ${tw`relative  bg-center bg-cover -mx-8`}
  background-image: url(${backgroundHomeSystem});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-10 pb-10 px-4 flex justify-between items-center flex-col lg:flex-row mx-auto`;
const LeftColumn = tw.div`flex flex-col lg:block lg:w-1/2 mx-auto`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const HeadingLite = styled.h1`
  ${tw`text-4xl text-left lg:text-left sm:text-3xl lg:text-4xl xl:text-4xl text-gray-100 leading-none text-center`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const InfoCompany = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm m-5`;

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <img class="mx-auto" src={ImgSrc} alt="empresa" />
          </LeftColumn>
          <RightColumn>
            <HeadingLite>
              <span>SISTEMA EXPERTO GENERA</span>
            </HeadingLite>
            <InfoCompany>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
              <br />
              <br />
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </InfoCompany>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};