import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import DataSrc from "images/data_anal.jpg";
import RepoSrc from "images/data_repo.jpg";

const Container = tw.div`relative`;

const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center  mx-auto mb-10`}
`;

const Column = styled.div`
  ${tw`border-2 border-primary-500 md:w-1/2 lg:w-1/2 max-w-lg m-4 bg-gray-100`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full  px-2 py-8`}
  .imageContainer {
    ${tw` text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2 w-1/2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4  text-secondary-100`}
  }
`;

const CardLeft = styled.div`
  ${tw`flex flex-col mx-auto w-1/2 max-w-lg items-center rounded-lg `}
  .number {
    ${tw`font-black text-6xl`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const ColumnContainer = tw.div`max-w-lg`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-center  mt-4 lg:mt-4 flex-col sm:flex-row`;
const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;

const HeadingLite = styled.h1`
  ${tw`text-3xl text-left lg:text-left sm:text-3xl lg:text-3xl xl:text-3xl  leading-none text-center`}
  span {
    ${tw`inline-block`}
  }
`;

export default ({}) => {
  return (
    <Container>
      <TwoColumnContainer>
        <Column>
          <Card>
            <CardLeft>
              <img class="mx-auto" src={DataSrc} alt="empresa" />
            </CardLeft>

            <span className="textContainer">
              <HeadingLite>
                <span>Analítica de datos</span>
              </HeadingLite>
              <p className="description">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga
              </p>
              <LinksContainer>
                <PrimaryLink href="/analitica_datos">
                  Más Información
                </PrimaryLink>
              </LinksContainer>
            </span>
          </Card>
        </Column>

        <Column>
          <Card>
            <div></div>
            <CardLeft>
              <img class="mx-auto" src={RepoSrc} alt="empresa" />
            </CardLeft>

            <span className="textContainer">
              <HeadingLite>
                <span>Repositorio de recursos</span>
              </HeadingLite>
              <p className="description">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga
              </p>
              <LinksContainer>
                <PrimaryLink href="/analitica_datos">
                  Más Información
                </PrimaryLink>
              </LinksContainer>
            </span>
          </Card>
        </Column>
      </TwoColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
