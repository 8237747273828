import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

//const Container = tw.div`relative pb-0`;
import backgroundHomeSystem from "images/cabecera_empresas_green.jpg";
const ContainerEmail = styled.div`
  ${tw`flex flex-col items-center relative -mx-8  bg-center bg-cover mt-4 text-white p-6 mt-10`}
  background-image: url(${backgroundHomeSystem});
`;

const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap max-w-screen-xl`;
const ColumnBis = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-4 flex`}
`;
const Plan = styled.div`
  ${tw` text-center  w-full px-8 rounded-lg text-white  `}
`;
const PlanHeader = styled.div`
  ${tw`flex flex-col  py-4 -mx-8 bg-[#c7aa45] text-xl`}
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col text-sm text-white text-left p-8`}
`;
const CardBis = styled.div`
  ${tw`flex flex-col mx-auto  items-center   border border-solid border-[#c7aa45] rounded-lg mt-6 bg-black bg-opacity-75 text-white `}
  .imageContainer {
    ${tw`border-0 border-[#c7aa45] text-center rounded-full p-4 flex-shrink-0 relative`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`mt-2 text-center`}
  }

  .title {
    ${tw` font-bold text-xl leading-none text-[#c7aa45]`}
  }

  .description {
    ${tw`mt-3 font-semibold text-white text-sm leading-loose text-left`}
  }
`;


export default () => {
  return (
    <ContainerEmail>
      <ThreeColumn>
        <ColumnBis key="1">
          <CardBis>
            <Plan>
              <PlanHeader>
                <span className="textContainer">
                  <span className="big_text">ORIGEN DEL PROYECTO</span>
                </span>
              </PlanHeader>

              <PlanFeatures>
                <p>
                  Comprometidos con la labor de ayudar a las empresas a ser más competitivas,
                  apostamos por contar con una herramienta propia que nos ayuda a definir una
                  hoja de ruta personalizada y fomentar la transición hacia escenarios de sostenibilidad.
                </p>
              </PlanFeatures>
            </Plan>
          </CardBis>
        </ColumnBis>

        <ColumnBis key="2">
          <CardBis>
            <Plan>
              <PlanHeader>
                <span className="textContainer">
                  <span className="big_text">EXPERIENCIA DE LOS PROMOTORES</span>
                </span>
              </PlanHeader>

              <PlanFeatures>
                <p>
                Los promotores del proyecto cuentan con un largo recorrido en
                materia de sostenibilidad. Conoce en detalle las experiencias previas en materia de 
                sostenibildiad haciendo click en el apartado "más información" de cada promotor.
                </p>
              </PlanFeatures>
            </Plan>
          </CardBis>
        </ColumnBis>

        <ColumnBis key="2">
          <CardBis>
            <Plan>
              <PlanHeader>
                <span className="textContainer">
                  <span className="big_text">COLABORADORES</span>
                </span>
              </PlanHeader>

              <PlanFeatures>
                <p>
                La creación de esta plataforma ha contado con MÁS DE 10 COLABORADORES, desde empresas que 
                han trasladado sus necesidades hasta asociaciones que han trasladado una visión integral 
                del sector. El desarrollo de la misma ha partido de necesidades reales de agentes reales.
                </p>
              </PlanFeatures>
            </Plan>
          </CardBis>
        </ColumnBis>


      </ThreeColumn>

    </ContainerEmail>
    
  );
};
