import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeadingLite2xl } from "components/misc/Headings.js";

import FaqsHome from "./FaqsHome";

// Estilos
const Container = tw.div`relative mb-4`;
const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-2 md:py-1`}
`;
const Heading = tw(
  SectionHeadingLite2xl
)`w-full font-bold text-gray-700 mt-2 text-2xl p-8`;
const HeadingLight = tw(
  SectionHeadingLite2xl
)`w-full font-normal text-gray-700 mt-0 text-base mb-2 p-8 pt-4`;


/**
 * Componente Principal
 */
export default () => {
  return (
    <Container>
      <FourColumnContainer>
        <Heading>UNA PLATAFORMA PARA CONOCER TU PUNTO DE PARTIDA Y PASAR A LA ACCIÓN</Heading>
        <HeadingLight>
        No se puede mejorar aquello que no se ha medido. La evaluación de ProgreeNFood te permite gestionar tu estrategia de sostenibilidad con el mismo rigor que otros indicadores de mercado y/o financieros. 
        </HeadingLight>

        <FaqsHome />
      </FourColumnContainer>
    </Container>
  );
};
