import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeadingMin } from "components/misc/Headings.js";

import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveAreaBump } from "@nivo/bump";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

import IcoEconomicaClientesColor from "../../images/IcoEconomicaClientesColor.png";
import IcoEconomicaClientesGris from "../../images/IcoEconomicaClientesGris.png";
import IcoEconomicaEstrategiaColor from "../../images/IcoEconomicaEstrategiaColor.png";
import IcoEconomicaEstrategiaGris from "../../images/IcoEconomicaEstrategiaGris.png";
import IcoEconomicaInnovacionColor from "../../images/IcoEconomicaInnovacionColor.png";
import IcoEconomicaInnovacionGris from "../../images/IcoEconomicaInnovacionGris.png";
import IcoEconomicaResultadoColor from "../../images/IcoEconomicaResultadoColor.png";
import IcoEconomicaResultadoGris from "../../images/IcoEconomicaResultadoGris.png";

// Estilos del Interfaz
const Intro = styled.div`
  ${tw`md:w-1/2 lg:w-full bg-[#96989b] p-2 pl-4 text-center  rounded-lg text-xl text-white`}
`;
const Container = tw.div`relative`;

const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto md:py-10 mb-1 text-left`}
`;
const Heading = tw(SectionHeadingMin)`w-full p-4`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-2  rounded-lg mt-0`}
  .imageContainer {
    ${tw`border-0 border-yellow-600 text-center rounded-full p-2 flex-shrink-0 relative`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-0 text-center  p-1`}
  }

  .textContainerGray {
    ${tw`mt-0 text-center p-1`}
  }

  .title {
    ${tw`mt-2  text-xl leading-none text-gray-700 `}
  }

  .title_characterization_active {
    ${tw`mt-0  text-base leading-none text-gray-700 `}
  }

  .title_economic_active {
    ${tw`mt-0  text-base leading-none text-orange-400 `}
  }

  .title_social_active {
    ${tw`mt-0  text-base leading-none text-blue-400 `}
  }

  .title_environment_active {
    ${tw`mt-0  text-base leading-none text-green-400 `}
  }

  .title_gray {
    ${tw`mt-0  text-base leading-none text-gray-400 `}
  }

  .horizontal {
    ${tw`border-yellow-600 h-1`}
  }

  .progress_gray_color {
    ${tw`bg-gray-700`}
  }

  .progress_orange_color {
    ${tw`bg-orange-400`}
  }

  .progress_blue_color {
    ${tw`bg-blue-400`}
  }

  .progress_green_color {
    ${tw`bg-green-400`}
  }
`;

const Link = tw.a` w-full sm:w-auto text-sm sm:text-base px-4 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-yellow-600 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;

const data = [
  {
    country: "TÚ",
    "Factor Uno": 37,
    "hot dogColor": "hsl(151, 70%, 50%)",
    "Factor Dos": 93,
    burgerColor: "hsl(249, 70%, 50%)",
    "Factor Tres": 160,
    sandwichColor: "hsl(314, 70%, 50%)",
    "Factor Cuatro": 172,
    kebabColor: "hsl(51, 70%, 50%)",
  },

  {
    country: "MEDIA EMPRESAS",
    "Factor Uno": 102,
    "hot dogColor": "hsl(53, 70%, 50%)",
    "Factor Dos": 132,
    burgerColor: "hsl(321, 70%, 50%)",
    "Factor Tres": 168,
    sandwichColor: "hsl(176, 70%, 50%)",
    "Factor Cuatro": 200,
    kebabColor: "hsl(353, 70%, 50%)",
  },
  {
    country: "EMP. REFERENCIA",
    "Factor Uno": 162,
    "hot dogColor": "hsl(275, 70%, 50%)",
    "Factor Dos": 110,
    burgerColor: "hsl(217, 70%, 50%)",
    "Factor Tres": 73,
    sandwichColor: "hsl(240, 70%, 50%)",
    "Factor Cuatro": 23,
    kebabColor: "hsl(22, 70%, 50%)",
  },
];

const MyResponsiveBar = ({ data /* see data tab */ }) => (
  <ResponsiveBar
    data={data}
    keys={["Factor Uno", "Factor Dos", "Factor Tres", "Factor Cuatro"]}
    indexBy="country"
    margin={{ top: 50, right: 130, bottom: 50, left: 120 }}
    padding={0.3}
    layout="horizontal"
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "fries",
        },
        id: "dots",
      },
      {
        match: {
          id: "sandwich",
        },
        id: "lines",
      },
    ]}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendPosition: "middle",
      legendOffset: 42,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendPosition: "middle",
      legendOffset: 0,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={(e) =>
      e.id + ": " + e.formattedValue + " in country: " + e.indexValue
    }
  />
);

const MyResponsiveBar2 = ({ data /* see data tab */ }) => (
  <ResponsiveBar
    data={data}
    keys={["Factor Uno", "Factor Dos", "Factor Tres", "Factor Cuatro"]}
    indexBy="country"
    margin={{ top: 50, right: 130, bottom: 50, left: 120 }}
    padding={0.3}
    layout="horizontal"
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "fries",
        },
        id: "dots",
      },
      {
        match: {
          id: "sandwich",
        },
        id: "lines",
      },
    ]}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "country",
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "food",
      legendPosition: "middle",
      legendOffset: -40,
    }}
    enableGridX={true}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={(e) =>
      e.id + ": " + e.formattedValue + " in country: " + e.indexValue
    }
  />
);

const datar = [
  {
    id: " ",
    data: [
      {
        x: "Empresa Referencia",
        y: 70,
      },
    ],
  },
  {
    id: "  ",
    data: [
      {
        x: "Tu",
        y: 50,
      },
    ],
  },
  {
    id: "   ",
    data: [
      {
        x: "Empresa Media",
        y: 20,
      },
    ],
  },
];

const MyResponsiveRadialBar = ({ data /* see data tab */ }) => (
  <ResponsiveRadialBar
      data={data}
      valueFormat=">-.2f"
      startAngle={-90}
      endAngle={90}
      innerRadius={0.2}
      padding={0.45}
      cornerRadius={2}
      maxValue={100}
      margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
      enableTracks={false}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      enableLabels={true}
      transitionMode="startAngle"
      legends={[
          {
              anchor: 'bottom',
              direction: 'column',
              justify: false,
              translateX: 19,
              translateY: -38,
              itemsSpacing: 6,
              itemDirection: 'left-to-right',
              itemWidth: 101,
              itemHeight: 18,
              itemTextColor: '#999',
              symbolSize: 18,
              symbolShape: 'square',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemTextColor: '#000'
                      }
                  }
              ]
          }
      ]}
  />
)

export default () => {
  return (
    <>
      <Intro style={{ backgroundColor: "bg-gray-300" }}>Resultados Pilar Economico</Intro>

      <div tw="grid grid-cols-2 gap-4 p-8">
        <div tw="rounded-lg bg-[#f9f9f9] p-2">
          <Container style={{ height: "350px" }}>
            <MyResponsiveRadialBar data={datar} />
          </Container>
        </div>
        <div tw="rounded-lg bg-[#f9f9f9] p-2">
          <Container style={{ height: "350px" }}>
            <MyResponsiveBar data={data} />
          </Container>
        </div>
      </div>

      <div tw="grid grid-cols-9 mb-4">
            <div tw="col-span-3 flex justify-center items-center">
              <PrimaryLink href="#">Pilar Económico</PrimaryLink>
            </div>
            <div tw="col-span-3 flex justify-center items-center">
              <PrimaryLink href="#">Pilar Social</PrimaryLink>
            </div>
            <div tw="col-span-3 flex justify-center items-center">
              <PrimaryLink href="#">Pilar Medioambiental</PrimaryLink>
            </div>
      </div>

      <Intro style={{ backgroundColor: "bg-gray-300" }}>Feedback</Intro>

      <div tw="grid grid-cols-2 gap-4 p-8">
        
        <div tw="rounded-lg bg-[#f9f9f9] p-2">
          <div tw="grid grid-cols-4 gap-4 p-2">
            <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
              <Card>
                <span>
                  <img src={IcoEconomicaClientesColor} alt="" />
                </span>

                <span className="textContainer">
                  <span className="title_economic_active">Estrategia</span>
                </span>
              </Card>
            </div>
            <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2">
              Para ser una empresa realmente competitiva, es clave identificar
              las fortalezas de tu modelo de negocio y colaborar con otros
              agentes para reforzar la propuesta de valor. XXX tiene un alto
              potencial de mejora en este área
            </div>
          </div>
        </div>

        <div tw="rounded-lg bg-[#f9f9f9] p-2">
          <div tw="grid grid-cols-4 gap-4 p-2">
            <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
              <Card>
                <span>
                  <img src={IcoEconomicaClientesColor} alt="" />
                </span>

                <span className="textContainer">
                  <span className="title_economic_active">Estrategia</span>
                </span>
              </Card>
            </div>
            <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2">
              Para ser una empresa realmente competitiva, es clave identificar
              las fortalezas de tu modelo de negocio y colaborar con otros
              agentes para reforzar la propuesta de valor. XXX tiene un alto
              potencial de mejora en este área
            </div>
          </div>
        </div>

        <div tw="rounded-lg bg-[#f9f9f9] p-2">
          <div tw="grid grid-cols-4 gap-4 p-2">
            <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
              <Card>
                <span>
                  <img src={IcoEconomicaClientesColor} alt="" />
                </span>

                <span className="textContainer">
                  <span className="title_economic_active">Estrategia</span>
                </span>
              </Card>
            </div>
            <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2">
              Para ser una empresa realmente competitiva, es clave identificar
              las fortalezas de tu modelo de negocio y colaborar con otros
              agentes para reforzar la propuesta de valor. XXX tiene un alto
              potencial de mejora en este área
            </div>
          </div>
        </div>

        <div tw="rounded-lg bg-[#f9f9f9] p-2">
          <div tw="grid grid-cols-4 gap-4 p-2">
            <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
              <Card>
                <span>
                  <img src={IcoEconomicaClientesColor} alt="" />
                </span>

                <span className="textContainer">
                  <span className="title_economic_active">Estrategia</span>
                </span>
              </Card>
            </div>
            <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2">
              Para ser una empresa realmente competitiva, es clave identificar
              las fortalezas de tu modelo de negocio y colaborar con otros
              agentes para reforzar la propuesta de valor. XXX tiene un alto
              potencial de mejora en este área
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
