import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import axios from "axios";
import configData from "config.json";

import EvaluationService from "services/evaluation.service";
import toast from "react-hot-toast";

// Iconos del Interfaz
import IcoInfo from "../../images/IcoInfo.png";
import IcoCaracterizacionColor from "../../images/IcoCaracterizacionColor.png";
import IcoEconomicaColor from "../../images/IcoEconomicaColor.png";
import IcoEconomicaGris from "../../images/IcoEconomicaGris.png";
import IcoSocialColor from "../../images/IcoSocialColor.png";
import IcoSocialGris from "../../images/IcoSocialGris.png";
import IcoMedioambientalColor from "../../images/IcoMedioambientalColor.png";
import IcoMedioambientalGris from "../../images/IcoMedioambientalGris.png";

import IcoEconomicaClientesColor from "../../images/IcoEconomicaClientesColor.png";
import IcoEconomicaClientesGris from "../../images/IcoEconomicaClientesGris.png";
import IcoEconomicaEstrategiaColor from "../../images/IcoEconomicaEstrategiaColor.png";
import IcoEconomicaEstrategiaGris from "../../images/IcoEconomicaEstrategiaGris.png";
import IcoEconomicaInnovacionColor from "../../images/IcoEconomicaInnovacionColor.png";
import IcoEconomicaInnovacionGris from "../../images/IcoEconomicaInnovacionGris.png";
import IcoEconomicaResultadoColor from "../../images/IcoEconomicaResultadoColor.png";
import IcoEconomicaResultadoGris from "../../images/IcoEconomicaResultadoGris.png";

import IcoSocialResponsabilidadColor from "../../images/IcoSocialResponsabilidadColor.png";
import IcoSocialResponsabilidadGris from "../../images/IcoSocialResponsabilidadGris.png";
import IcoSocialCalidadColor from "../../images/IcoSocialCalidadColor.png";
import IcoSocialCalidadGris from "../../images/IcoSocialCalidadGris.png";
import IcoSocialTrabajadoresColor from "../../images/IcoSocialTrabajadoresColor.png";
import IcoSocialTrabajadoresGris from "../../images/IcoSocialTrabajadoresGris.png";
import IcoSocialDesarrolloColor from "../../images/IcoSocialDesarrolloColor.png";
import IcoSocialDesarrolloGris from "../../images/IcoSocialDesarrolloGris.png";

import IcoAmbientalCumplimientoColor from "../../images/IcoAmbientalCumplimientoColor.png";
import IcoAmbientalCumplimientoGris from "../../images/IcoAmbientalCumplimientoGris.png";
import IcoAmbientalEnvasesColor from "../../images/IcoAmbientalEnvasesColor.png";
import IcoAmbientalEnvasesGris from "../../images/IcoAmbientalEnvasesGris.png";
import IcoAmbientalEficienciaColor from "../../images/IcoAmbientalEficienciaColor.png";
import IcoAmbientalEficienciaGris from "../../images/IcoAmbientalEficienciaGris.png";
import IcoAmbientalEmisionesColor from "../../images/IcoAmbientalEmisionesColor.png";
import IcoAmbientalEmisionesGris from "../../images/IcoAmbientalEmisionesGris.png";

// Estilos del Interfaz
let Intro = styled.div`
  ${tw`w-full sm:w-full md:w-4/5 lg:w-4/5 bg-[#96989b] p-0 pl-4 text-center  rounded-md text-lg text-white`}
`;
const Question = styled.div`
  ${tw`w-4/5 sm:w-4/5 md:w-4/5 lg:w-4/5 bg-[#f9f9f9] p-2 pl-4 mt-0   rounded-lg text-base font-normal text-gray-700`}
`;
const InfoQuestion = styled.div`
  ${tw`w-4/5 sm:w-4/5 md:w-1/2 lg:w-4/5 bg-[#CBD5E0] p-4 pl-6 mt-8   rounded-lg text-base font-normal text-gray-700`}
`;
const InfoSelection = styled.div`
  ${tw`w-4/5 md:w-4/5 lg:w-4/5 p-0 pl-4 mt-2   rounded-lg text-sm font-normal text-red-700`}
`;
const AnswersContainer = styled.div`
  ${tw`md:w-3/5 lg:w-4/5 bg-white p-2 pl-4 mt-0   rounded-lg text-base  text-gray-700`}
`;
const AnswersMatrixContainer = styled.div`
  ${tw`md:w-1/2 lg:w-4/5 bg-white p-2 pl-4 mt-0   rounded-lg text-base  text-gray-700`}
`;
const QuestionPager = styled.div`
  ${tw`sm:w-full md:w-full lg:w-4/5 bg-[#f9f9f9] p-2 pl-4 mt-0   rounded-lg text-lg text-gray-700`}
`;
const Container = tw.div`relative`;
const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-5 md:py-10 `}
`;
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/4 px-4 hidden md:block flex`}
`;
const Columnf = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/4 px-4 flex`}
`;
const Card = styled.div`
  ${tw`flex 2xl:flex-col xl:flex-col lg:flex-col md:flex-col flex-row mx-auto w-full items-center px-6 py-2  rounded-lg mt-0`}
  .imageContainer {
    ${tw`border-0 border-yellow-600 text-center rounded-full p-2 flex-shrink-0 relative`}
    img {
      ${tw`w-6 h-6`}
    }
  }
  .textContainer {
    ${tw`mt-0 text-center  p-0`}
  }
  .textContainerGray {
    ${tw`mt-0 text-center p-0`}
  }
  .title {
    ${tw`mt-2  text-xl leading-none text-gray-700 `}
  }
  .title_characterization_active {
    ${tw`mt-0  text-base leading-none text-[#494948] `}
  }
  .title_economic_active {
    ${tw`mt-0  text-base leading-none text-[#F0A000] `}
  }
  .title_social_active {
    ${tw`mt-0  text-base leading-none text-[#867796] `}
  }
  .title_environment_active {
    ${tw`mt-0  text-base leading-none text-[#41AD2B] `}
  }
  .title_gray {
    ${tw`mt-0  text-base leading-none text-gray-400 `}
  }
  .horizontal {
    ${tw`border-yellow-600 h-1`}
  }
  .progress_gray_color {
    ${tw`bg-[#494948]`}
  }
  .progress_orange_color {
    ${tw`bg-[#F0A000]`}
  }
  .progress_blue_color {
    ${tw`bg-[#867796]`}
  }
  .progress_green_color {
    ${tw`bg-[#41AD2B]`}
  }
`;
const Link = tw.a` w-full sm:w-auto text-sm sm:text-base px-4 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-normal border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#424445] text-gray-100 shadow-lg hocus:bg-gray-600 hocus:text-gray-200`;

/**
 * Definición del Componente
 */
export default () => {
  const [showResults, setShowResults] = React.useState(false);
  const [startedEvaluation, setStartedEvaluation] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [numQuestions, setNumQuestions] = useState(0);
  const [question, setQuestion] = useState({});
  const [titlePillar, setTitlePillar] = useState("");
  const [idPillar, setIdPillar] = useState(0);
  const [colorPillar, setColorPillar] = useState("f9f9f9");
  const [titleFactor, setTitleFactor] = useState("");
  const [bgOp1, setBgOp1] = useState("#f9f9f9");
  const [bgOp2, setBgOp2] = useState("#f9f9f9");
  const [bgOp3, setBgOp3] = useState("#f9f9f9");
  const [bgOp4, setBgOp4] = useState("#f9f9f9");
  const [questionsCaracterizationPercent, SetQuestionsCaracterizationPercent] =
    useState("0%");
  const [questionsEconomicPercent, SetQuestionsEconomicPercent] =
    useState("0%");
  const [questionsSocialPercent, SetQuestionsSocialPercent] = useState("0%");
  const [questionsEnvironmentalPercent, SetQuestionsEnvironmentalPercent] =
    useState("0%");
  const [currentSelectValue, setCurrentSelectValue] = useState("");
  const [matrixValues, setMatrixValues] = useState([
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1
  ]);
  const [multipleValues, setMultipleValues] = useState([-1, -1, -1, -1]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [finishedQuestionsFactorRoute, setFinishedQuestionsFactorRoute] = useState(false);
  const navigate = useNavigate();

  /**
   * Se ejecuta al cargar la página
   */
  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user_token"));
    let baseURL = configData.API_BASE_URL + "started_evaluation/get_current";

    axios
      .get(baseURL, { headers: { Authorization: `Bearer ${userToken}` } })
      .then((response) => {
        setStartedEvaluation(response.data.data);
        let d = response.data.data;

        // Obtenemos las preguntas del factor actual
        // TENEMOS QUE OBTENER LAS PREGUNTAS DE LA STARTED_EVALUATION ACTUAL
        //const api_url = configData.API_BASE_URL + "question/get_all_by_evaluation_and_factor/" + d.evaluation.id + '/' + d.factor.id;
        const api_url =
          configData.API_BASE_URL +
          "question/get_all_by_started_evaluation_and_factor/" +
          d.id +
          "/" +
          d.factor.id;

        axios
          .get(api_url, { headers: { Authorization: `Bearer ${userToken}` } })
          .then((resp) => {
            let factor_questions = resp.data.data;
            console.log("factor_questions --->");
            console.log(factor_questions);
            update_gui(d, factor_questions, false);
          });
      })
      .catch((exeption) => {
        toast.error("Conectando ..");
        //logout_system();
      });
  }, [bgOp1, bgOp2, bgOp3, bgOp4]); // eslint-disable-line react-hooks/exhaustive-deps

  const set_bgopts_by_default = () => {
    setBgOp1("#f9f9f9");
    setBgOp2("#f9f9f9");
    setBgOp3("#f9f9f9");
    setBgOp4("#f9f9f9");
  };

  /**
   * Salida de la parte privada de la aplicación
   */
  const logout_system = () => {
    //localStorage.removeItem("user_token");
    window.location.reload();
  };

  /**
   * Actualización del interfaz gráfico
   */
  const update_gui = (d, factor_questions, flag) => {
    console.log('flag -> '+ flag);
    console.log("startedEvaluation->");
    console.log(d);
    console.log("factor_questions->");
    console.log(factor_questions);
    setQuestions(factor_questions);
    setNumQuestions(factor_questions.length);

    // Si hemos finalizado el pilar vamos a los resultados
    
    if (d.pillar.id == 2) {
      if ((d.finished_economic_pillar == 1) && flag) {
        navigate("/resultados_economicos");
      }
    } else if (d.pillar.id == 3) {
      if ((d.finished_social_pillar == 1) && flag) {
        navigate("/resultados_sociales");
      }
    } else if (d.pillar.id == 4) {
      if ((d.finished_environment_pillar == 1) && flag) {
        if (d.finished) {
          navigate("/resultados_evaluacion");
        } else {
          navigate("/resultados_medio_ambiente");
        }
      } else {
        if (d.finished) {
          navigate("/resultados_evaluacion");
        }
      }
    }
    

    // Datos del Pilar
    setTitlePillar(d.pillar.title);
    setIdPillar(d.pillar.id);
    setColorPillar(d.pillar.color);

    // Datos del Factor
    setTitleFactor(d.factor.name);

    // Estadísticas de progreso
    let characterization_questions =
      d.evaluation.characterization.characterization_questions;
    let tmp_calc = parseInt(
      (parseInt(d.selected_characterization_questions.length) /
        parseInt(characterization_questions.length)) *
        100
    );
    SetQuestionsCaracterizationPercent(tmp_calc + "%");

    let selected_economic_pillar_questions = 0;
    let selected_social_pillar_questions = 0;
    let selected_environment_pillar_questions = 0;
    for (let j = 0; j < d.selected_questions.length; j++) {
      if (d.selected_questions[j].question.pillar.id == 2) {
        selected_economic_pillar_questions += 1;
      } else if (d.selected_questions[j].question.pillar.id == 3) {
        selected_social_pillar_questions += 1;
      } else if (d.selected_questions[j].question.pillar.id == 4) {
        selected_environment_pillar_questions += 1;
      }
    }
    tmp_calc = parseInt(
      (parseInt(selected_economic_pillar_questions) /
        parseInt(d.total_questions_economic_pillar)) *
        100
    );
    SetQuestionsEconomicPercent(tmp_calc + "%");

    tmp_calc = parseInt(
      (parseInt(selected_social_pillar_questions) /
        parseInt(d.total_questions_social_pillar)) *
        100
    );
    SetQuestionsSocialPercent(tmp_calc + "%");

    tmp_calc = parseInt(
      (parseInt(selected_environment_pillar_questions) /
        parseInt(d.total_questions_environment_pillar)) *
        100
    );
    SetQuestionsEnvironmentalPercent(tmp_calc + "%");

    // Pregunta actual
    setQuestion(factor_questions[d.current_question_position]);

    // Marcamos la respuesta/respuestas seleccionada/s
    let is_selected_option = false;
    setSelectedOption(false);

    if (factor_questions[d.current_question_position].option_type == "OPCION") {
      for (let i = 0; i < d.selected_questions.length; i++) {
        if (
          d.selected_questions[i].question.id ==
          factor_questions[d.current_question_position].id
        ) {
          is_selected_option = true;
          console.log("marcamos respuesta seleccionada");
          setColorSelectedOption(d.selected_questions[i].option_selected);
          setSelectedOption(true);
        }
      }

      if (!is_selected_option) {
        console.log("no hay opciones seleccionadas -> valores por defecto");
        console.log(factor_questions[d.current_question_position]);
        set_bgopts_by_default();
      }
    } else if (
      factor_questions[d.current_question_position].option_type == "MATRIZ"
    ) {
      for (let i = 0; i < d.selected_questions.length; i++) {
        if (
          d.selected_questions[i].question.id ==
          factor_questions[d.current_question_position].id
        ) {
          setMatrixValues(JSON.parse(d.selected_questions[i].value_selected));
          setSelectedOption(true);
        }
      }
    } else if (
      factor_questions[d.current_question_position].option_type == "MULTIOPCION"
    ) {
      for (let i = 0; i < d.selected_questions.length; i++) {
        if (
          d.selected_questions[i].question.id ==
          factor_questions[d.current_question_position].id
        ) {
          setMultipleValues(JSON.parse(d.selected_questions[i].value_selected));
          setSelectedOption(true);
        }
      }
    }
  };

  /**
   *
   * Mostrar información ayuda
   */
  const toggleInfo = () => {
    setShowResults(!showResults);
  };

  /**
   * Helper colores seleccion simple
   */
  const setColorSelectedOption = (option) => {
    if (option == "op_1") {
      setBgOp1("#CBD5E0");
      setBgOp2("#f9f9f9");
      setBgOp3("#f9f9f9");
      setBgOp4("#f9f9f9");
    } else if (option == "op_2") {
      setBgOp1("#f9f9f9");
      setBgOp2("#CBD5E0");
      setBgOp3("#f9f9f9");
      setBgOp4("#f9f9f9");
    } else if (option == "op_3") {
      setBgOp1("#f9f9f9");
      setBgOp2("#f9f9f9");
      setBgOp3("#CBD5E0");
      setBgOp4("#f9f9f9");
    } else if (option == "op_4") {
      setBgOp1("#f9f9f9");
      setBgOp2("#f9f9f9");
      setBgOp3("#f9f9f9");
      setBgOp4("#CBD5E0");
    }
  };

  /**
   * Selección de un valor de una lista
   */
  const selectListOption = (question, option) => {
    setCurrentSelectValue(option);
    console.log("currentSelectValue");
    console.log(option);

    let selected_characterization_question = {
      started_evaluation_id: startedEvaluation.id,
      characterization_question_id: question.id,
      type_selected: question.type,
      option_selected: option,
      value_selected: question.list,
    };

    // console.log(selected_characterization_question);

    EvaluationService.add_selected_characterization_question(
      selected_characterization_question
    )
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          setSelectedOption(true);
          //toast.success("Seleccionada Opción");
        } else {
          toast.error("Error de Acceso en Adición.");
        }
      })
      .catch((exeption) => {
        toast.error("Conectando ...");
      });
  };

  /**
   * Selección de un checkbox de la pregunta
   */
  const selectCheckbox = (e, question) => {
    let seleccionada_opcion = false;
    let tmp = e.target.name.split("_");
    let idx = tmp[1];
    let update_array = [];
    for (let i = 0; i < matrixValues.length; i++) {
      if (i == idx) {
        if (matrixValues[i] == -1) {
          seleccionada_opcion = true;
          update_array[i] = e.target.value;
        } else {
          update_array[i] = -1;
        }
      } else {
        update_array[i] = matrixValues[i];
      }
    }
    setMatrixValues(update_array);

    let selected_checkbox_question = {
      started_evaluation_id: startedEvaluation.id,
      question_id: question.id,
      type_selected: question.option_type,
      option_selected: "",
      value_selected: update_array,
    };

    EvaluationService.add_selected_checkbox_question(selected_checkbox_question)
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          if (seleccionada_opcion) {
            setSelectedOption(true);
            //toast.success("Seleccionada Opción");
          } else {
            setSelectedOption(false);
            //toast.success("Opción Deseleccionada");
          }
        } else {
          toast.error("Error de Acceso en Adición.");
        }
      })
      .catch((exeption) => {
        toast.error("Conectando ...");
      });
  };

  /**
   * Adicion/Eliminacion de una pregunta condicional de la lista de preguntas de
   * un Factor de una StartedEvaluation
   */
  const add_or_remove_conditional_question = (
    started_evaluation,
    question,
    option
  ) => {
    if (question.use_conditional_questions == 1) {
      // Pregunta que usa condicionales
      EvaluationService.add_or_remove_conditional_question(
        started_evaluation,
        question,
        option
      )
        .then((value) => {
          if (value.data.success) {
            setQuestions(value.data.data);
            const userToken = JSON.parse(localStorage.getItem("user_token"));
            let api_url =
              configData.API_BASE_URL + "started_evaluation/get_current";
            axios
              .get(api_url, {
                headers: { Authorization: `Bearer ${userToken}` },
              })
              .then((resp) => {
                setStartedEvaluation(resp.data.data);
                update_gui(resp.data.data, value.data.data, false);
              });
          } else {
            toast.error("Error de Acceso en actualización");
          }
        })
        .catch((exception) => {
          toast.error("Error de Conexión");
        });
    }
  };

  /**
   * Selección de una opción simple de la pregunta
   */
  const selectSimpleOption = (question, option) => {
    setColorSelectedOption(option);

    let tmp = option.split("_");
    let idx = tmp[1];
    let points = question["points_" + idx];
    let ifval = question["if_" + idx];
    let selected_question = {
      started_evaluation_id: startedEvaluation.id,
      question_id: question.id,
      type_selected: question.option_type,
      option_selected: option,
      value_selected: points,
    };

    EvaluationService.add_selected_question(selected_question)
      .then((value) => {
        if (value.data.success) {
            

          setStartedEvaluation(value.data.data);
          setSelectedOption(true);
          //toast.success("Seleccionada Opción");

          add_or_remove_conditional_question(
            startedEvaluation,
            question,
            ifval
          );
        } else {
          toast.error("Error de Acceso en Adición.");
        }
      })
      .catch((exeption) => {
        toast.error("Error de Acceso");
      });
  };

  /**
   * Seleccion opciones multiples en la pregunta
   */
  const selectMultipleOption = (question, option) => {
    console.log("dentro de selectMultipleOption");

    let seleccionada_opcion = false;
    let tmp = option.split("_");
    let idx = parseInt(tmp[1]);

    let update_array = [];

    for (let i = 0; i < multipleValues.length; i++) {
      if (i == idx - 1) {
        if (multipleValues[i] == -1) {
          seleccionada_opcion = true;
          update_array[i] = question["points_" + idx];
        } else {
          update_array[i] = -1;
        }
      } else {
        update_array[i] = multipleValues[i];
      }
    }
    setMultipleValues(update_array);

    let selected_multiple_question = {
      started_evaluation_id: startedEvaluation.id,
      question_id: question.id,
      type_selected: question.option_type,
      option_selected: "",
      value_selected: update_array,
    };

    console.log(update_array);

    EvaluationService.add_selected_multiple_question(selected_multiple_question)
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          if (seleccionada_opcion) {
            setSelectedOption(true);
            //toast.success("Seleccionada Opción");
          } else {
            setSelectedOption(false);
            //toast.success("Opción Deseleccionada");
          }
        } else {
          toast.error("Error de Acceso en Adición.");
        }
      })
      .catch((exeption) => {
        toast.error("Conectando ...");
      });
  };

  /**
   * Pregunta siguiente
   */
  const getNextQuestion = () => {
    // para controlar cuando finalizamos el ultimo factor de un pilar
    // y poder mostrar la pagina de resultados
    let flag = false;  

    if (!selectedOption) {
      let current_question_id = startedEvaluation.current_question_id;
      let there_is_selection = false;
      for (let j=0; j< startedEvaluation.selected_questions.length; j++) {
        if (startedEvaluation.selected_questions[j].question['id'] == current_question_id) {
          if (startedEvaluation.selected_questions[j].value_selected) {
            for (let k=0; k<startedEvaluation.selected_questions[j].value_selected.length; k++) {
              if (startedEvaluation.selected_questions[j].value_selected[k] != -1) {
                there_is_selection = true;
              }
            }
          } 
        }
      }

      if (!there_is_selection) {
        toast.error("Error: No ha realizado ninguna selección");
        return;
      }
        
    } else {
      setSelectedOption(false);
    }

    //console.log(startedEvaluation.current_question_position);
    //console.log(startedEvaluation);

    if (startedEvaluation.current_question_position < numQuestions - 1) {
      // Pregunta que no usa condicionales
      let updated_started_evaluation = {
        id: startedEvaluation.id,
        current_question_position:
          startedEvaluation.current_question_position + 1,
      };

      EvaluationService.update_started_evaluation(updated_started_evaluation)
        .then((value) => {
          if (value.data.success) {
            setStartedEvaluation(value.data.data);
            setMultipleValues([-1, -1, -1, -1]);
            setShowResults(false);
            update_gui(value.data.data, questions, false);

            //window.location.reload();
          } else {
            toast.error("Error de Acceso en actualización");
          }
        })
        .catch((exception) => {
          toast.error("Conectando ...");
          //logout_system();
        });

    } else {
      
      // Obtenemos el numero de preguntas seleccionadas en el factor actual
      let length_sel_questions = 0;
      let updated_started_evaluation = {};

      startedEvaluation.selected_questions.forEach((e) => {
        if (e.question.factor.id == startedEvaluation.factor.id) {
          length_sel_questions++;
        }
      });

      console.log("length_sel_questions");
      console.log(length_sel_questions);
      console.log(numQuestions);

      if (length_sel_questions < numQuestions) {
        toast.error(
          "ERROR. No todas las preguntas están contestadas. Por favor, revise sus respuestas."
        );
      } else {
        // Navegamos al siguiente factor o al final del pilar actual

        if (startedEvaluation.factor.id == 4) {
          flag = true;
          // Final del Factor economico
          updated_started_evaluation = {
            id: startedEvaluation.id,
            finished_economic_pillar: 1,
            finished_resultado_factor: 1,
          };
        } else if (startedEvaluation.factor.id == 8) {
          flag = true;
          // Final del Factor social
          updated_started_evaluation = {
            id: startedEvaluation.id,
            finished_social_pillar: 1,
            finished_desarrollo_factor: 1,
          };
        } else if (startedEvaluation.factor.id == 12) {
          flag = true;
          // Final del Factor ambiental
          updated_started_evaluation = {
            id: startedEvaluation.id,
            finished_environment_pillar: 1,
            finished_emisiones_factor: 1,
          };
        } else {
          // Permanecemos en el pilar actual, finalizamos el factor actual y Navegamos al siguiente factor
          if (startedEvaluation.factor.id == 1) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_estrategia_factor: 1,
              factor_id: 2,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 2) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_clientes_factor: 1,
              factor_id: 3,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 3) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_innovacion_factor: 1,
              factor_id: 4,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 5) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_responsabilidad_factor: 1,
              factor_id: 6,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 6) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_calidad_factor: 1,
              factor_id: 7,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 7) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_trabajadores_factor: 1,
              factor_id: 8,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 9) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_cumplimiento_factor: 1,
              factor_id: 10,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 10) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_envases_factor: 1,
              factor_id: 11,
              current_question_position: 0,
            };
          } else if (startedEvaluation.factor.id == 11) {
            updated_started_evaluation = {
              id: startedEvaluation.id,
              finished_eficiencia_factor: 1,
              factor_id: 12,
              current_question_position: 0,
            };
          }
        }

        console.log("UPDATE_STARTED_EVALUATION");
        console.log(updated_started_evaluation);

        EvaluationService.update_started_evaluation(updated_started_evaluation)
          .then((value) => {
            const userToken = JSON.parse(localStorage.getItem("user_token"));

            if (value.data.success) {
              let d = value.data.data;
              setStartedEvaluation(value.data.data);
              console.log("EVALUACION ACTUALIZADA+++++++++++");
              console.log(value.data.data);

              // Obtenemos las preguntas del nuevo factor actual
              //const api_url = configData.API_BASE_URL + "question/get_all_by_evaluation_and_factor/" + d.evaluation.id + '/' + d.factor.id;
              const api_url =
                configData.API_BASE_URL +
                "question/get_all_by_started_evaluation_and_factor/" +
                d.id +
                "/" +
                d.factor.id;

              axios
                .get(api_url, {
                  headers: { Authorization: `Bearer ${userToken}` },
                })
                .then((resp) => {
                  let factor_questions = resp.data.data;
                  console.log("NUEVAS FACTOR QUESTIONS***********");
                  console.log(factor_questions);

                  toast.success("Comenzando Siguiente Factor");

                  setMultipleValues([-1, -1, -1, -1]);
                  update_gui(d, factor_questions, flag);
                });
            } else {
              toast.error("Error de Actualización");
            }
          })
          .catch((exception) => {
            toast.error("Conectando ...");
            //logout_system();
          });
      }
    }
  };

  /**
   * Pregunta anterior
   */
  const getPreviousQuestion = () => {
    if (!selectedOption) {

      let current_question_id = startedEvaluation.current_question_id;
      let there_is_selection = false;
      for (let j=0; j< startedEvaluation.selected_questions.length; j++) {
        if (startedEvaluation.selected_questions[j].question['id'] == current_question_id) {
          if (startedEvaluation.selected_questions[j].value_selected) {
            for (let k=0; k<startedEvaluation.selected_questions[j].value_selected.length; k++) {
              if (startedEvaluation.selected_questions[j].value_selected[k] != -1) {
                there_is_selection = true;
              }
            }
          } 
        }
      }

      if (!there_is_selection) {
        toast.error("Error: No ha realizado ninguna selección");
        return;
      }

    } else {
      setSelectedOption(true);
    }

    if (startedEvaluation.current_question_position > 0) {
      let updated_started_evaluation = {
        id: startedEvaluation.id,
        current_question_position:
          startedEvaluation.current_question_position - 1,
      };

      EvaluationService.update_started_evaluation(updated_started_evaluation)
        .then((value) => {
          if (value.data.success) {
            setStartedEvaluation(value.data.data);
            update_gui(value.data.data, questions, false);
          } else {
            toast.error("Error de Actualización");
          }
        })
        .catch((exception) => {
          toast.error("Conectando ...");
          //logout_system();
        });
    } else {
      toast.error("ERROR. Ya se encuentra al principio");
    }
  };

  /**
   * Navegar pregunta específica por posicion
   */
  const goToQuestionByPosition = (question_position) => {
    if (!selectedOption) {
      toast.error("Error: No ha realizado ninguna selección");
      return;
    }

    let updated_started_evaluation = {
      id: startedEvaluation.id,
      current_question_position: question_position,
    };

    EvaluationService.update_started_evaluation(updated_started_evaluation)
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          update_gui(value.data.data, questions, false);
        } else {
          toast.error("Error de Actualización");
        }
      })
      .catch((exception) => {
        toast.error("Conectando ...");
      });
  };

  /**
   * Navegar a un Pilar y factor específico
   */
  const goToPilarAndFactor = (pillar_id, factor_id) => {
    if (pillar_id == 1) {
      toast.error("No está permitido modificar la fase de Caracterización");
      return;
    }

    //console.log(questions);

    let updated_started_evaluation = {
      id: startedEvaluation.id,
      current_question_position: 0,
      current_question_id: 0,
      pillar_id: pillar_id,
      factor_id: factor_id,
    };

    EvaluationService.update_started_evaluation(updated_started_evaluation)
      .then((value) => {
        const userToken = JSON.parse(localStorage.getItem("user_token"));

        if (value.data.success) {
          let d = value.data.data;
          setStartedEvaluation(value.data.data);
          console.log("EVALUACION ACTUALIZADA+++++++++++");
          console.log(value.data.data);

          // Obtenemos las preguntas del nuevo factor actual
          //const api_url = configData.API_BASE_URL + "question/get_all_by_evaluation_and_factor/" + d.evaluation.id + '/' + d.factor.id;
          const api_url =
            configData.API_BASE_URL +
            "question/get_all_by_started_evaluation_and_factor/" +
            d.id +
            "/" +
            d.factor.id;

          axios
            .get(api_url, {
              headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((resp) => {
              let factor_questions = resp.data.data;
              console.log("NUEVAS FACTOR QUESTIONS***********");
              //console.log(factor_questions);

              //toast.success("Cargando datos factor ...");

              //setMultipleValues([-1, -1, -1, -1]);
              update_gui(d, factor_questions, false);
            });
        } else {
          toast.error("Error de Actualización");
        }
      })
      .catch((exception) => {
        toast.error("Conectando ...");
        //logout_system();
      });
  };

  // Generación de la fila con la lista de Pilares
  const gen_card = (card) => {
    const get_progressbar = (card) => {
      if (card.pilar_id == 1) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw=" h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: questionsCaracterizationPercent }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 2) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: questionsEconomicPercent }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 3) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: questionsSocialPercent }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 4) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: questionsEnvironmentalPercent }}
            ></div>
          </div>
        );
      }
    };

    if (card.pilar_id == idPillar) {
      return (
        <a
          href="#"
          onClick={() => {
            goToPilarAndFactor(card.pilar_id, card.first_factor_id);
          }}
        >
          <Card>
            <span className="imageContainer">
              <img src={card.image_src_active} alt="" />
            </span>

            {get_progressbar(card)}

            <span className="textContainer">
              <span className={card.class_title_active}>{card.title}</span>
            </span>
          </Card>
        </a>
      );
    } else {
      if (startedEvaluation["finished_" + card.tag] == 1) {
        return (
          <a
            href="#"
            onClick={() => {
              goToPilarAndFactor(card.pilar_id, card.first_factor_id);
            }}
          >
            <Card>
              <span className="imageContainer">
                <img src={card.image_src_active} alt="" />
              </span>

              {get_progressbar(card)}

              <span className="textContainer">
                <span className={card.class_title_active}>{card.title}</span>
              </span>
            </Card>
          </a>
        );
      } else {
        return (
          <a
            href="#"
            onClick={() => {
              goToPilarAndFactor(card.pilar_id, card.first_factor_id);
            }}
          >
            <Card>
              <span className="imageContainer">
                <img src={card.image_src_inactive} alt="" />
              </span>

              {get_progressbar(card)}

              <span className="textContainerGray">
                <span className={card.class_title_inactive}>{card.title}</span>
              </span>
            </Card>
          </a>
        );
      }
    }
  };

  const gen_factor_card_min = (factor) => {
    // Mostramos solo los factores del pilar en el que nos encontramos
    if (factor.pillar_id == idPillar) {
      if (startedEvaluation["finished_" + factor.tag] == 1) {
        return (
          <Card tw="w-full p-1">
            <span className="textContainer">
              <span tw="text-sm" className={factor.class_title_active}>
                &#10003; {factor.title}
              </span>
            </span>
          </Card>
        );
      } else {
        if (startedEvaluation.factor.id == factor.factor_id) {
          return (
            <Card tw="w-full p-1">
              <span className="textContainer">
                <span className={factor.class_title_active}>
                  &#x25B8; {factor.title}
                </span>
              </span>
            </Card>
          );
        } else {
          return (
            <Card tw="w-full p-1">
              <span className="textContainerGray" tw="p-0">
                <span className={factor.class_title_inactive}>
                  {factor.title}
                </span>
              </span>
            </Card>
          );
        }
      }
    }
  };

  /**
   * Generación de la fila de iconos de Factores
   */
  const gen_factor_card = (factor) => {
    //console.log(factor);
    //console.log(idPillar);
    //console.log(startedEvaluation);

    // Mostramos solo los factores del pilar en el que nos encontramos
    if (factor.pillar_id == idPillar) {
      if (startedEvaluation["finished_" + factor.tag] == 1) {
        return (
          <a
            href="#"
            onClick={() => {
              goToPilarAndFactor(factor.pillar_id, factor.factor_id);
            }}
          >
            <Card>
              <span>
                <img src={factor.image_src_active} alt="" />
              </span>

              <span className="textContainer">
                <span className={factor.class_title_active}>
                  {factor.title}
                </span>
              </span>
            </Card>
          </a>
        );
      } else {
        if (startedEvaluation.factor.id == factor.factor_id) {
          return (
            <a
              href="#"
              onClick={() => {
                goToPilarAndFactor(factor.pillar_id, factor.factor_id);
              }}
            >
              <Card>
                <span>
                  <img src={factor.image_src_active} alt="" />
                </span>

                <span className="textContainer">
                  <span className={factor.class_title_active}>
                    {factor.title}
                  </span>
                </span>
              </Card>
            </a>
          );
        } else {
          return (
            <a
              href="#"
              onClick={() => {
                goToPilarAndFactor(factor.pillar_id, factor.id);
              }}
            >
              <Card>
                <span>
                  <img src={factor.image_src_inactive} alt="" />
                </span>

                <span className="textContainerGray">
                  <span className={factor.class_title_inactive}>
                    {factor.title}
                  </span>
                </span>
              </Card>
            </a>
          );
        }
      }
    }
  };

  /**
   * Generación de un select
   */
  const generate_question_select = (question) => {
    let options_select = question.list.split(";");
    return (
      <select
        value={currentSelectValue}
        onChange={(e) => {
          selectListOption(question, e.target.value);
        }}
        name="q{question.id}"
        id="q{question.id}"
        tw="text-white p-2 bg-[#96989b]  text-xl rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full"
      >
        {options_select.map((os, i) => (
          <option key={os} value={os}>
            {os}
          </option>
        ))}
      </select>
    );
  };

  // Definición de los Pilares
  const cards = [
    {
      pilar_id: 1,
      first_factor_id: 0,
      first_question_id: 0,
      tag: "characterization_pillar",
      image_src_active: IcoCaracterizacionColor,
      image_src_inactive: IcoCaracterizacionColor,
      title: "CARACTERIZACIÓN",
      class_title_active: "title_characterization_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_gray_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 2,
      first_factor_id: 1,
      first_question_id: 1,
      tag: "economic_pillar",
      image_src_active: IcoEconomicaColor,
      image_src_inactive: IcoEconomicaGris,
      title: "PILAR ECONÓMICO",
      class_title_active: "title_economic_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_orange_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 3,
      first_factor_id: 5,
      first_question_id: 12,
      tag: "social_pillar",
      image_src_active: IcoSocialColor,
      image_src_inactive: IcoSocialGris,
      title: "PILAR SOCIAL",
      class_title_active: "title_social_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_blue_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 4,
      first_factor_id: 9,
      first_question_id: 42,
      tag: "environment_pillar",
      image_src_active: IcoMedioambientalColor,
      image_src_inactive: IcoMedioambientalGris,
      title: "PILAR MEDIOAMBIENTAL",
      class_title_active: "title_environment_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_green_color",
      class_progress_background: "bg-gray-200",
    },
  ];

  // Definición de los Factores
  const factorCards = [
    {
      factor_id: 1,
      pillar_id: 2,
      title: "Estrategia",
      tag: "estrategia_factor",
      image_src_active: IcoEconomicaEstrategiaColor,
      image_src_inactive: IcoEconomicaEstrategiaGris,
      class_title_active: "title_economic_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 2,
      pillar_id: 2,
      title: "Clientes",
      tag: "clientes_factor",
      image_src_active: IcoEconomicaClientesColor,
      image_src_inactive: IcoEconomicaClientesGris,
      class_title_active: "title_economic_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 3,
      pillar_id: 2,
      title: "Innovación",
      tag: "innovacion_factor",
      image_src_active: IcoEconomicaInnovacionColor,
      image_src_inactive: IcoEconomicaInnovacionGris,
      class_title_active: "title_economic_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 4,
      pillar_id: 2,
      title: "Resultados e impacto Económico",
      tag: "resultado_factor",
      image_src_active: IcoEconomicaResultadoColor,
      image_src_inactive: IcoEconomicaResultadoGris,
      class_title_active: "title_economic_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 5,
      pillar_id: 3,
      title: "Responsabilidad social y gobernanza",
      tag: "responsabilidad_factor",
      image_src_active: IcoSocialResponsabilidadColor,
      image_src_inactive: IcoSocialResponsabilidadGris,
      class_title_active: "title_social_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 6,
      pillar_id: 3,
      title: "Calidad alimentaria y nutricional",
      tag: "calidad_factor",
      image_src_active: IcoSocialCalidadColor,
      image_src_inactive: IcoSocialCalidadGris,
      class_title_active: "title_social_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 7,
      pillar_id: 3,
      title: "Trabajadores",
      tag: "trabajadores_factor",
      image_src_active: IcoSocialTrabajadoresColor,
      image_src_inactive: IcoSocialTrabajadoresGris,
      class_title_active: "title_social_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 8,
      pillar_id: 3,
      title: "Desarrollo de la comunidad",
      tag: "desarrollo_factor",
      image_src_active: IcoSocialDesarrolloColor,
      image_src_inactive: IcoSocialDesarrolloGris,
      class_title_active: "title_social_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 9,
      pillar_id: 4,
      title: "Cumplimiento ambiental",
      tag: "cumplimiento_factor",
      image_src_active: IcoAmbientalCumplimientoColor,
      image_src_inactive: IcoAmbientalCumplimientoGris,
      class_title_active: "title_environment_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 10,
      pillar_id: 4,
      title: "Envases",
      tag: "envases_factor",
      image_src_active: IcoAmbientalEnvasesColor,
      image_src_inactive: IcoAmbientalEnvasesGris,
      class_title_active: "title_environment_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 11,
      pillar_id: 4,
      title: "Eficiencia en consumo",
      tag: "eficiencia_factor",
      image_src_active: IcoAmbientalEficienciaColor,
      image_src_inactive: IcoAmbientalEficienciaGris,
      class_title_active: "title_environment_active",
      class_title_inactive: "title_gray",
    },
    {
      factor_id: 12,
      pillar_id: 4,
      title: "Emisiones y residuos",
      tag: "emisiones_factor",
      image_src_active: IcoAmbientalEmisionesColor,
      image_src_inactive: IcoAmbientalEmisionesGris,
      class_title_active: "title_environment_active",
      class_title_inactive: "title_gray",
    },
  ];

  /**
   * Generación de una matriz
   */
  const generate_question_matrix = (question) => {
    let first_row = question.matrix_column_titles.split(";");
    first_row.unshift("Opciones");

    return (
      <AnswersMatrixContainer>
        <div tw="grid grid-cols-5 gap-1">
          <div tw="rounded bg-[#f9f9f9] p-1">
            <div tw="sm:pl-0 pl-2 text-center text-sm flex justify-center items-center">
              Opciones
            </div>
          </div>

          <div tw="rounded bg-orange-400 text-white text-xs sm:pl-0 p-2">
            <div tw="text-center flex justify-center items-center">
              No se apoya
            </div>
          </div>
          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="text-center flex justify-center items-center">
              Para conocer las tendencias del mercado
            </div>
          </div>
          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="text-center flex justify-center items-center">
              Para la creación de nuevos productos/servicios
            </div>
          </div>
          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw=" text-center flex justify-center items-center">
              Para poner en marcha la venta/distribucion conjunta de nuevos
              productos/servicios
            </div>
          </div>

          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Universidades
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[0] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_0"
                name="op_0"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[0] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[1] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_1"
                name="op_1"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[1] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[2] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_2"
                name="op_2"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[2] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[3] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_3"
                name="op_3"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[3] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>


          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Centros de Formación Profesional
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[4] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_4"
                name="op_4"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[4] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[5] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_5"
                name="op_5"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[5] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[6] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_6"
                name="op_6"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[6] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[7] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_7"
                name="op_7"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[7] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>


          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Proveedores
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[8] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_8"
                name="op_8"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[8] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[9] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_9"
                name="op_9"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[9] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[10] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_10"
                name="op_10"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[10] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[11] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_11"
                name="op_11"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[11] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Centros Tecnológicos
            </div>
          </div>

          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[12] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_12"
                name="op_12"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[12] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[13] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_13"
                name="op_13"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[13] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[14] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_14"
                name="op_14"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[14] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[15] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_15"
                name="op_15"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[15] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>



          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Clústeres
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[16] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_16"
                name="op_16"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[16] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[17] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_17"
                name="op_17"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[17] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[18] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_18"
                name="op_18"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[18] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[19] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_19"
                name="op_19"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[19] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>


          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Instituciones Públicas
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[20] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_20"
                name="op_20"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[20] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[21] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_21"
                name="op_21"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[21] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[22] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_22"
                name="op_22"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[22] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[23] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_23"
                name="op_23"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[23] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>


          <div tw="rounded bg-orange-400 text-white text-xs p-2">
            <div tw="p-2 text-center flex justify-center items-center">
              Competidores
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[24] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_24"
                name="op_24"
                value="0"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[24] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[25] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_25"
                name="op_25"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[25] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[26] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_26"
                name="op_26"
                value="30"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[26] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>
          <div
            tw="rounded-lg bg-[#f9f9f9] p-2"
            style={{
              backgroundColor: matrixValues[27] != -1 ? "#CBD5E0" : "#f9f9f9",
            }}
          >
            <div tw="p-2 text-center flex justify-center items-center">
              <input
                id="op_27"
                name="op_27"
                value="40"
                type="checkbox"
                tw="h-6 w-6"
                checked={matrixValues[27] != -1}
                onChange={(e) => selectCheckbox(e, question)}
              />
            </div>
          </div>

        </div>
      </AnswersMatrixContainer>
    );
  };

  // Definición del Componente
  return (
    <Container>
      <FourColumnContainer tw="mt-10 sm:mt-0">
        {cards.map((card, i) => (
          <Column key={i}>{gen_card(card)}</Column>
        ))}

        <Intro tw="md:mt-4 mt-1 " style={{ backgroundColor: colorPillar }}>
          {titlePillar} / {titleFactor}
        </Intro>

        <FourColumnContainer tw="w-full md:w-3/5 py-1">
          {factorCards.map((factor, i) => (
            <>
              <Columnf tw="hidden md:block" key={i}>
                {gen_factor_card(factor)}
              </Columnf>

            </>
          ))}
        </FourColumnContainer>

        <Question>
          <div tw="grid grid-cols-12">
            <div tw="col-span-2 flex justify-center items-center">
              <span tw="text-4xl">
                {startedEvaluation.current_question_position + 1}
              </span>{" "}
              / {numQuestions}
            </div>

            {question.id == 25 && (
              <div
                tw="col-span-9"
                dangerouslySetInnerHTML={{ __html: question.content }}
              ></div>
            )}
            {question.id !== 25 && (
              <div
                tw="col-span-9 flex justify-center items-center"
                dangerouslySetInnerHTML={{ __html: question.content }}
              ></div>
            )}

            <div tw="col-span-1 flex justify-center items-center p-4">
              {(question.help || question.help_image) && (
                <a href="#">
                  <img tw="w-8" src={IcoInfo} alt="" onClick={toggleInfo} />
                </a>
              )}
            </div>
          </div>
        </Question>

        {showResults && (
          <InfoQuestion>
            {question.help}
            {question.help_image && (
              <div>
                <img
                  tw="mx-auto"
                  src={require("../../images/" + question.help_image)}
                  alt=""
                  onClick={toggleInfo}
                />
              </div>
            )}
          </InfoQuestion>
        )}

        <InfoSelection>
          <div tw="grid grid-cols-1">
            {question.option_type == "OPCION" && (
              <span>
                Selecciona <span tw="font-bold">LA OPCIÓN</span> que más se
                ajuste a tu empresa
              </span>
            )}

            {(question.option_type == "MULTIOPCION" ||
              question.option_type == "MATRIZ") && (
              <span>
                Selecciona <span tw="font-bold">TODAS LAS OPCIONES</span> que
                más se ajusten a tu empresa
              </span>
            )}
          </div>
        </InfoSelection>

        <AnswersContainer>
          {question.option_type == "LIST" && (
            <div>{generate_question_select(question)}</div>
          )}

          {question.option_type == "OPCION" && (
            <div tw="grid grid-cols-2 gap-2">
              {question.op_1 && (
                <div
                  key="q_op1"
                  id="q_op1"
                  style={{ backgroundColor: bgOp1 }}
                  tw="w-full col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectSimpleOption(question, "op_1")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="#">A</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_1}</a>
                  </div>
                </div>
              )}

              {question.op_2 && (
                <div
                  key="q_op2"
                  id="q_op2"
                  style={{ backgroundColor: bgOp2 }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectSimpleOption(question, "op_2")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="">B</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_2}</a>
                  </div>
                </div>
              )}

              {question.op_3 && (
                <div
                  key="q_op3"
                  id="q_op3"
                  style={{ backgroundColor: bgOp3 }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectSimpleOption(question, "op_3")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="">C</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_3}</a>
                  </div>
                </div>
              )}

              {question.op_4 && (
                <div
                  key="q_op4"
                  id="q_op4"
                  style={{ backgroundColor: bgOp4 }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectSimpleOption(question, "op_4")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="">D</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_4}</a>
                  </div>
                </div>
              )}
            </div>
          )}

          {question.option_type == "MULTIOPCION" && (
            <div tw="grid grid-cols-2 gap-2">
              {question.op_1 && (
                <div
                  key="q_op1"
                  id="q_op1"
                  style={{
                    backgroundColor:
                      multipleValues[0] != -1 ? "#CBD5E0" : "#f9f9f9",
                  }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectMultipleOption(question, "op_1")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="#">A</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_1}</a>
                  </div>
                </div>
              )}

              {question.op_2 && (
                <div
                  key="q_op2"
                  id="q_op2"
                  style={{
                    backgroundColor:
                      multipleValues[1] != -1 ? "#CBD5E0" : "#f9f9f9",
                  }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectMultipleOption(question, "op_2", true)}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="">B</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_2}</a>
                  </div>
                </div>
              )}

              {question.op_3 && (
                <div
                  key="q_op3"
                  id="q_op3"
                  style={{
                    backgroundColor:
                      multipleValues[2] != -1 ? "#CBD5E0" : "#f9f9f9",
                  }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectMultipleOption(question, "op_3")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="">C</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_3}</a>
                  </div>
                </div>
              )}

              {question.op_4 && (
                <div
                  key="q_op4"
                  id="q_op4"
                  style={{
                    backgroundColor:
                      multipleValues[3] != -1 ? "#CBD5E0" : "#f9f9f9",
                  }}
                  tw="col-span-2 sm:col-span-1 md:col-span-1 grid grid-cols-6 rounded-lg hover:bg-gray-400 bg-[#f9f9f9] p-2"
                  onClick={(e) => selectMultipleOption(question, "op_4")}
                >
                  <div
                    tw="col-span-1 bg-[#96989b] rounded-md text-center text-2xl text-white flex justify-center items-center"
                    style={{ backgroundColor: colorPillar }}
                  >
                    <a href="">D</a>
                  </div>
                  <div tw="col-span-5 pl-4 text-left flex items-center">
                    <a href="#">{question.op_4}</a>
                  </div>
                </div>
              )}
            </div>
          )}
        </AnswersContainer>

        {question.option_type == "MATRIZ" && generate_question_matrix(question)}

        <QuestionPager tw="p-4 mb-10">
          <div tw="grid grid-cols-12">
            <div tw="col-span-4 md:col-span-2 flex justify-center items-center">
              <PrimaryLink tw="rounded" href="#" onClick={getPreviousQuestion}>
                {"<<"}Anterior
              </PrimaryLink>
            </div>

            <div tw="col-span-4 md:col-span-8 text-center invisible md:visible">
              {questions.map((cq, i) => (
                <a
                  tw="text-xl"
                  href="#"
                  onClick={() => goToQuestionByPosition(i)}
                >
                  {startedEvaluation.current_question_position == i && (
                    <span tw="p-2 bg-gray-400">{i + 1}</span>
                  )}
                  {startedEvaluation.current_question_position != i && (
                    <span tw="p-2">{i + 1}</span>
                  )}
                </a>
              ))}
            </div>

            <div tw="col-span-4 md:col-span-2 flex justify-center items-center">
              <PrimaryLink tw="rounded" href="#" onClick={getNextQuestion}>
                {">>"}Siguiente
              </PrimaryLink>
            </div>
          </div>
        </QuestionPager>
      </FourColumnContainer>
    </Container>
  );
};
