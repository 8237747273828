import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import axios from "axios";
import configData from "config.json";

//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeadingMin } from "components/misc/Headings.js";

import EvaluationService from "services/evaluation.service";
import toast from "react-hot-toast";

import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";

import IcoEconomicaClientesColor from "images/IcoEconomicaClientesColorNF.png";
import IcoEconomicaEstrategiaColor from "../../images/IcoEconomicaEstrategiaColorNF.png";
import IcoEconomicaInnovacionColor from "../../images/IcoEconomicaInnovacionColorNF.png";
import IcoEconomicaResultadoColor from "../../images/IcoEconomicaResultadoColorNF.png";

import IcoSocialResponsabilidadColor from "../../images/IcoSocialResponsabilidadColorNF.png";
import IcoSocialCalidadColor from "../../images/IcoSocialCalidadColorNF.png";
import IcoSocialTrabajadoresColor from "../../images/IcoSocialTrabajadoresColorNF.png";
import IcoSocialDesarrolloColor from "../../images/IcoSocialDesarrolloColorNF.png";

import IcoAmbientalCumplimientoColor from "../../images/IcoAmbientalCumplimientoColorNF.png";
import IcoAmbientalEficienciaColor from "../../images/IcoAmbientalEficienciaColorNF.png";
import IcoAmbientalEmisionesColor from "../../images/IcoAmbientalEmisionesColorNF.png";
import IcoAmbientalEnvasesColor from "../../images/IcoAmbientalEnvasesColorNF.png";


import IcoFeedGenEconomicamenteSostenible from "../../images/IcoFeedGenEconomicamenteSostenible.png";
import IcoFeedGenMedioambientalmenteSostenible from "../../images/IcoFeedGenMedioambientalmenteSostenible.png";
import IcoFeedGenSocialmenteSostenible from "../../images/IcoFeedGenSocialmenteSostenible.png";

import IcoFeedGenSostenible from "../../images/IcoFeedGenSostenible.gif";
import IcoFeedGenViable from "../../images/IcoFeedGenViable.gif";
import IcoFeedGenSoportable from "../../images/IcoFeedGenSoportable.gif";
import IcoFeedGenEquitativo from "../../images/IcoFeedGenEquitativo.gif";
import IcoFeedGenVulnerable from "../../images/IcoFeedGenVulnerable.gif";

// Estilos del Interfaz
const Intro = styled.div`
  ${tw`md:w-1/2 lg:w-full bg-[#96989b] p-2 pl-4 text-center  rounded-lg text-xl text-white`}
`;

const IntroMedium = styled.div`
  ${tw`md:w-1/2 lg:w-full bg-[#96989b]   text-center  rounded-lg text-lg text-white`}
`;

const Container = tw.div`relative`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-2  rounded-lg mt-0`}
  .imageContainer {
    ${tw`border-0 border-yellow-600 text-center rounded-full p-2 flex-shrink-0 relative`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-0 text-center  p-1`}
  }

  .textContainerGray {
    ${tw`mt-0 text-center p-1`}
  }

  .title {
    ${tw`mt-2  text-xl leading-none text-[#494948] `}
  }

  .title_characterization_active {
    ${tw`mt-0  text-base leading-none text-[#494948] `}
  }

  .title_economic_active {
    ${tw`mt-0  text-base leading-none text-[#F0A000] `}
  }

  .title_social_active {
    ${tw`mt-0  text-base leading-none text-[#867796] `}
  }

  .title_environment_active {
    ${tw`mt-0  text-base leading-none text-[#41AD2B] `}
  }

  .title_general_active {
    ${tw`mt-0  text-base leading-none `}
  }

  .title_gray {
    ${tw`mt-0  text-base leading-none text-gray-400 `}
  }

  .horizontal {
    ${tw`border-yellow-600 h-1`}
  }

  .progress_gray_color {
    ${tw`bg-[#494948]`}
  }

  .progress_orange_color {
    ${tw`bg-[#F0A000]`}
  }

  .progress_blue_color {
    ${tw`bg-[#867796]`}
  }

  .progress_green_color {
    ${tw`bg-[#41AD2B]`}
  }
`;

const Link = tw.a` w-full sm:w-auto text-sm sm:text-base px-4 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-2 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-xs md:text-sm text-center bg-yellow-600 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 h-8 py-1`;

export default () => {
  const [startedEvaluation, setStartedEvaluation] = useState([]);
  const [referenceCompany, setReferenceCompany] = useState({});
  const [evaluationFeedback, setEvaluationFeedback] = useState({});
  const [feedbackPillar, setFeedbackPillar] = useState({});
  const [showGeneralResult, setShowGeneralResult] = useState(true);
  const [showEconomicPillar, setShowEconomicPillar] = useState(false);
  const [showSocialPillar, setShowSocialPillar] = useState(false);
  const [showEnvironmentPillar, setShowEnvironmentPillar] = useState(false);

  const viewGeneralResult = () => {
    setShowGeneralResult(true);
    setShowEconomicPillar(false);
    setShowSocialPillar(false);
    setShowEnvironmentPillar(false);
  };

  const viewEconomicPillar = () => {
    setShowEconomicPillar(true);
    setShowSocialPillar(false);
    setShowEnvironmentPillar(false);
    setShowGeneralResult(false);
  };

  const viewSocialPillar = () => {
    setShowEconomicPillar(false);
    setShowSocialPillar(true);
    setShowEnvironmentPillar(false);
    setShowGeneralResult(false);
  };

  const viewEnvironmentPillar = () => {
    setShowEconomicPillar(false);
    setShowSocialPillar(false);
    setShowEnvironmentPillar(true);
    setShowGeneralResult(false);
  };

  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user_token"));
    let baseURL = configData.API_BASE_URL + "started_evaluation/get_current";
    axios
      .get(baseURL, { headers: { Authorization: `Bearer ${userToken}` } })
      .then((response) => {
        console.log(response.data.data);

        setStartedEvaluation(response.data.data);
        setReferenceCompany(response.data.data.reference_company);
        if (response.data.data.started_evaluation_feedback) {
          setEvaluationFeedback(response.data.data.started_evaluation_feedback);
          if (response.data.data.started_evaluation_feedback.feedback_pillar) {
            setFeedbackPillar(
              response.data.data.started_evaluation_feedback.feedback_pillar
            );
          }
        }

        let d = response.data.data;

        //console.log(d);

        // Actualización del interface
        update_gui(d);
      });
  }, []);

  /**
   * Actualización del interfaz gráfico
   */
  const update_gui = (d) => {
    // Generamos el feedback para esta evaluacion si no lo hemos echo ya
    if (!d.started_evaluation_feedback) {
      let startedEvaluation = {
        id: d.id,
      };

      EvaluationService.generate_started_evaluation_feedbacks(startedEvaluation)
        .then((value) => {
          if (value.data.success) {
            setStartedEvaluation(value.data.data);
            setEvaluationFeedback(value.data.data.started_evaluation_feedback);
            setFeedbackPillar(
              value.data.data.started_evaluation_feedback.feedback_pillar
            );
            window.location.reload();
            toast.success("Datos Evaluación Generados");
          } else {
            toast.error("Error de Acceso en actualización");
          }
        })
        .catch((exception) => {
          toast.error("Conectando ...");
          window.location.reload();
        });
    }
  };

  const printReport = () => {
    toast.success("Generando Informe");
    const hideFrame = document.createElement("iframe");
    hideFrame.setAttribute("name", "printf");
    hideFrame.style.position = "fixed";
    hideFrame.style.right = "0";
    hideFrame.style.bottom = "0";
    hideFrame.style.width = "0";
    hideFrame.style.height = "0";
    hideFrame.style.border = "0";

    hideFrame.onload = () => {
      window.setTimeout(() => {
        //window.frames["printf"].focus();
        window.frames["printf"].print();
      }, 2000);
    };
    hideFrame.src =
      configData.WEBAPP_BASE_URL + "resultados_evaluacion_informe";
    document.body.appendChild(hideFrame);
  };

  // Graficos Feedback General
  ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
  const optionsPolarChart = {
    scale: {
      min: 0,
      max: 100,
      ticks: {
        display: true,
        beginAtZero: true,
      },
    },
  };

  const dataPolarCompany = {
    labels: ["Pilar Económico", "Pilar Social", "Pilar Medio Ambiental"],
    datasets: [
      {
        label: "%",
        data: [
          parseInt(startedEvaluation.percent_economic),
          parseInt(startedEvaluation.percent_social),
          parseInt(startedEvaluation.percent_environment),
        ],
        backgroundColor: [
          "#F0A000",
          "#867796",
          "#41AD2B",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataPolarReferenceCompany = {
    labels: ["Pilar Económico", "Pilar Social", "Pilar Medio Ambiental"],
    datasets: [
      {
        label: "%",
        data: [
          referenceCompany.val_economico_porcentaje,
          referenceCompany.val_social_porcentaje,
          referenceCompany.val_medioambiental_porcentaje,
        ],
        backgroundColor: [
          "#F0A000",
          "#867796",
          "#41AD2B",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataPolarAvgCompany = {
    labels: ["Pilar Económico", "Pilar Social", "Pilar Medio Ambiental"],
    datasets: [
      {
        label: "%",
        data: [
          referenceCompany.avg_val_economico_porcentaje,
          referenceCompany.avg_val_social_porcentaje,
          referenceCompany.avg_val_medioambiental_porcentaje,
        ],
        backgroundColor: [
          "#F0A000",
          "#867796",
          "#41AD2B",
        ],
        borderWidth: 1,
      },
    ],
  };

  const MyPolarAreaCompany = ({ data }) => (
    <PolarArea data={data} options={optionsPolarChart} />
  );

  // Graficos Pilar Economico
  const dataBarEconomico = [
    {
      country: "TÚ",
      Estrategia: startedEvaluation.percent_estrategia,
      Clientes: startedEvaluation.percent_clientes,
      Innovacion: startedEvaluation.percent_innovacion,
      Resultados: startedEvaluation.percent_resultados,
    },
/*
    {
      country: "MEDIA EMPRESAS",
      Estrategia: referenceCompany.avg_val_economico_estrategia,
      Clientes: referenceCompany.avg_val_economico_clientes,
      Innovacion: referenceCompany.avg_val_economico_innovacion,
      Resultados: referenceCompany.avg_val_economico_resultados,
    },
*/
    {
      country: "EMP. REFERENCIA",
      Estrategia: referenceCompany.val_economico_estrategia,
      Clientes: referenceCompany.val_economico_clientes,
      Innovacion: referenceCompany.val_economico_innovacion,
      Resultados: referenceCompany.val_economico_resultados,
    },
  ];
  const MyResponsiveBarEconomico = ({ data /* see data tab */ }) => (
    <ResponsiveBar
      animate={false}
      maxValue={100}
      data={data}
      keys={["Estrategia", "Clientes", "Innovacion", "Resultados"]}
      indexBy="country"
      margin={{ top: 50, right: 130, bottom: 50, left: 120 }}
      padding={0.3}
      layout="horizontal"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "yellow_orange_red" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 42,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );

  const dataRadialEconomico = [
    {
      id: " ",
      data: [
        {
          x: "Empresa Referencia",
          y: referenceCompany.val_economico_porcentaje,
        },
      ],
    },
    {
      id: "  ",
      data: [
        {
          x: "Tu",
          y: startedEvaluation.percent_economic,
        },
      ],
    },
/*    
    {
      id: "   ",
      data: [
        {
          x: "Empresa Media",
          y: referenceCompany.avg_val_economico_porcentaje,
        },
      ],
    },
*/
  ];
  const MyResponsiveRadialBarEconomico = ({ data /* see data tab */ }) => (
    <ResponsiveRadialBar
      colors={{ scheme: "dark2" }}
      data={data}
      valueFormat=">-.2f"
      startAngle={-90}
      endAngle={90}
      innerRadius={0.2}
      padding={0.45}
      cornerRadius={2}
      maxValue={100}
      margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
      enableTracks={false}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      enableLabels={true}
      transitionMode="startAngle"
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: 19,
          translateY: -38,
          itemsSpacing: 6,
          itemDirection: "left-to-right",
          itemWidth: 101,
          itemHeight: 18,
          itemTextColor: "#999",
          symbolSize: 18,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );

  const dataBarSocial = [
    {
      country: "TÚ",
      "Responsabilidad Social": startedEvaluation.percent_responsabilidad,
      "Calidad Alimentaria": startedEvaluation.percent_calidad,
      Trabajadores: startedEvaluation.percent_trabajadores,
      "Desarrollo Comunidad": startedEvaluation.percent_desarrollo,
    },
/*
    {
      country: "MEDIA EMPRESAS",
      "Responsabilidad Social": referenceCompany.avg_val_social_responsabilidad,
      "Calidad Alimentaria": referenceCompany.avg_val_social_calidad,
      Trabajadores: referenceCompany.avg_val_social_trabajadores,
      "Desarrollo Comunidad": referenceCompany.avg_val_social_desarrollo,
    },
*/
    {
      country: "EMP. REFERENCIA",
      "Responsabilidad Social": referenceCompany.val_social_responsabilidad,
      "Calidad Alimentaria": referenceCompany.val_social_calidad,
      Trabajadores: referenceCompany.val_social_trabajadores,
      "Desarrollo Comunidad": referenceCompany.val_social_desarrollo,
    },

  ];
  const MyResponsiveBarSocial = ({ data /* see data tab */ }) => (
    <ResponsiveBar
      maxValue={100}
      data={data}
      keys={[
        "Responsabilidad Social",
        "Calidad Alimentaria",
        "Trabajadores",
        "Desarrollo Comunidad",
      ]}
      indexBy="country"
      margin={{ top: 50, right: 130, bottom: 50, left: 120 }}
      padding={0.3}
      layout="horizontal"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "purple_blue_green" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 42,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );

  const dataRadialSocial = [
    {
      id: " ",
      data: [
        {
          x: "Empresa Referencia",
          y: referenceCompany.val_social_porcentaje,
        },
      ],
    },
    {
      id: "  ",
      data: [
        {
          x: "Tu",
          y: startedEvaluation.percent_social,
        },
      ],
    },
/*
    {
      id: "   ",
      data: [
        {
          x: "Empresa Media",
          y: referenceCompany.avg_val_social_porcentaje,
        },
      ],
    },
*/
  ];
  const MyResponsiveRadialBarSocial = ({ data /* see data tab */ }) => (
    <ResponsiveRadialBar
      colors={{ scheme: "dark2" }}
      data={data}
      valueFormat=">-.2f"
      startAngle={-90}
      endAngle={90}
      innerRadius={0.2}
      padding={0.45}
      cornerRadius={2}
      maxValue={100}
      margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
      enableTracks={false}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      enableLabels={true}
      transitionMode="startAngle"
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: 19,
          translateY: -38,
          itemsSpacing: 6,
          itemDirection: "left-to-right",
          itemWidth: 101,
          itemHeight: 18,
          itemTextColor: "#999",
          symbolSize: 18,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );

  const dataBarAmbiental = [
    {
      country: "TÚ",
      "Cumplimiento Ambiental": startedEvaluation.percent_cumplimiento,
      Envases: startedEvaluation.percent_envases,
      Eficiencia: startedEvaluation.percent_eficiencia,
      Emisiones: startedEvaluation.percent_emisiones,
    },

/*
    {
      country: "MEDIA EMPRESAS",
      "Cumplimiento Ambiental":
        referenceCompany.avg_val_medioambiental_cumplimiento,
      Envases: referenceCompany.avg_val_medioambiental_envases,
      Eficiencia: referenceCompany.avg_val_medioambiental_eficiencia,
      Emisiones: referenceCompany.avg_val_medioambiental_emisiones,
    },
*/

    {
      country: "EMP. REFERENCIA",
      "Cumplimiento Ambiental":
        referenceCompany.val_medioambiental_cumplimiento,
      Envases: referenceCompany.val_medioambiental_envases,
      Eficiencia: referenceCompany.val_medioambiental_eficiencia,
      Emisiones: referenceCompany.val_medioambiental_emisiones,
    },
  ];
  const MyResponsiveBarAmbiental = ({ data /* see data tab */ }) => (
    <ResponsiveBar
      maxValue={100}
      data={data}
      keys={["Cumplimiento Ambiental", "Envases", "Eficiencia", "Emisiones"]}
      indexBy="country"
      margin={{ top: 50, right: 130, bottom: 50, left: 120 }}
      padding={0.3}
      layout="horizontal"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "greens" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 42,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );

  const dataRadialAmbiental = [
    {
      id: " ",
      data: [
        {
          x: "Empresa Referencia",
          y: referenceCompany.val_medioambiental_porcentaje,
        },
      ],
    },
    {
      id: "  ",
      data: [
        {
          x: "Tu",
          y: startedEvaluation.percent_environment,
        },
      ],
    },
/*
    {
      id: "   ",
      data: [
        {
          x: "Empresa Media",
          y: referenceCompany.avg_val_medioambiental_porcentaje,
        },
      ],
    },
*/
  ];
  const MyResponsiveRadialBarAmbiental = ({ data /* see data tab */ }) => (
    <ResponsiveRadialBar
      colors={{ scheme: "dark2" }}
      data={data}
      valueFormat=">-.2f"
      startAngle={-90}
      endAngle={90}
      innerRadius={0.2}
      padding={0.45}
      cornerRadius={2}
      maxValue={100}
      margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
      enableTracks={false}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      enableLabels={true}
      transitionMode="startAngle"
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: 19,
          translateY: -38,
          itemsSpacing: 6,
          itemDirection: "left-to-right",
          itemWidth: 101,
          itemHeight: 18,
          itemTextColor: "#999",
          symbolSize: 18,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );

  return (
    <>
      <div tw="grid grid-cols-10 mb-4 mt-16 ">
        <div tw="col-span-2 flex justify-center items-center">
          {showGeneralResult && (
            <PrimaryLink 
              href="#" 
              style={{ backgroundColor: "#008080" }}
              tw="rounded"
            >
              Resultados Generales
            </PrimaryLink>
          )}
          {!showGeneralResult && (
            <PrimaryLink
              href="#"
              onClick={viewGeneralResult}
              style={{ backgroundColor: "lightslategray" }}
              tw="rounded"
            >
              Resultados Generales
            </PrimaryLink>
          )}
        </div>

        <div tw="col-span-2 flex justify-center items-center">
          {showEconomicPillar && (
            <PrimaryLink 
              href="#" 
              style={{ backgroundColor: "#F0A000" }}
              tw="rounded"
            >
              Económico
            </PrimaryLink>
          )}
          {!showEconomicPillar && (
            <PrimaryLink
              href="#"
              onClick={viewEconomicPillar}
              style={{ backgroundColor: "lightslategray" }}
              tw="rounded"
            >
              Económico
            </PrimaryLink>
          )}
        </div>

        <div tw="col-span-2 flex justify-center items-center">
          {showSocialPillar && (
            <PrimaryLink 
              href="#" 
              style={{ backgroundColor: "#867796" }}
              tw="rounded"
            >
              Social
            </PrimaryLink>
          )}
          {!showSocialPillar && (
            <PrimaryLink
              href="#"
              tw="rounded"
              onClick={viewSocialPillar}
              style={{ backgroundColor: "lightslategray" }}
            >
              Social
            </PrimaryLink>
          )}
        </div>

        <div tw="col-span-2 flex justify-center items-center">
          {showEnvironmentPillar && (
            <PrimaryLink 
              href="#" 
              tw="rounded" 
              style={{ backgroundColor: "#41AD2B" }}
            >
              Medioambiental
            </PrimaryLink>
          )}
          {!showEnvironmentPillar && (
            <PrimaryLink
              href="#"
              tw="rounded"
              onClick={viewEnvironmentPillar}
              style={{ backgroundColor: "lightslategray" }}
            >
              Medioambiental
            </PrimaryLink>
          )}
        </div>

        <div tw="col-span-2 flex justify-center items-center">
          <PrimaryLink 
            href="#"
            tw="rounded"
            style={{ backgroundColor: "MediumPurple" }} 
            onClick={printReport}
          >
            Generar Informe
          </PrimaryLink>
        </div>
      </div>

      {showGeneralResult && (
        <>
          <Intro style={{ backgroundColor: "#008080" }}>
            Resultados Generales
          </Intro>

          <div tw="grid grid-cols-3 gap-4 p-4 mb-6">

            <div tw="col-span-3 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2 justify-center flex items-center">
              <Container style={{ height: "340px" }}>
                <IntroMedium style={{ backgroundColor: "#008080" }}>
                  Mi Empresa [
                  {parseInt(
                    (parseInt(startedEvaluation.percent_economic) +
                      parseInt(startedEvaluation.percent_social) +
                      parseInt(startedEvaluation.percent_environment)) /
                      3
                  )}
                  %]
                </IntroMedium>
                <MyPolarAreaCompany
                  data={dataPolarCompany}
                  options={optionsPolarChart}
                />
              </Container>
            </div>


            <div tw="col-span-3 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2 justify-center flex items-center hidden">
              <Container style={{ height: "340px" }}>
                <IntroMedium style={{ backgroundColor: "#008080" }}>
                  Empresa Media [
                  {parseInt(
                    (parseInt(referenceCompany.avg_val_economico_porcentaje) +
                      parseInt(referenceCompany.avg_val_social_porcentaje) +
                      parseInt(
                        referenceCompany.avg_val_medioambiental_porcentaje
                      )) /
                      3
                  )}
                  %]
                </IntroMedium>
                <MyPolarAreaCompany data={dataPolarAvgCompany} />
              </Container>
            </div>

            <div tw="col-span-3 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2 justify-center flex items-center">
              <Container style={{ height: "340px" }}>
                <IntroMedium style={{ backgroundColor: "#008080" }}>
                  Empresa Referencia [
                  {parseInt(
                    (parseInt(referenceCompany.val_economico_porcentaje) +
                      parseInt(referenceCompany.val_social_porcentaje) +
                      parseInt(
                        referenceCompany.val_medioambiental_porcentaje
                      )) /
                      3
                  )}
                  %]
                </IntroMedium>
                <MyPolarAreaCompany data={dataPolarReferenceCompany} />
              </Container>
            </div>
          </div>

          <Intro style={{ backgroundColor: "#008080" }}>Feedback</Intro>

          <div tw="rounded-lg bg-[#f9f9f9] p-2">
            <div tw="grid grid-cols-1 gap-4 p-2">
              <div tw="rounded-lg col-span-1 bg-[#f9f9f9] ">
                <Card tw="w-[8rem]">
                  <span tw="p-2">
                    {feedbackPillar.id == 1 && (
                      <img src={IcoFeedGenSostenible} alt="" />
                    )}
                    {feedbackPillar.id == 2 && (
                      <img src={IcoFeedGenEconomicamenteSostenible} alt="" />
                    )}
                    {feedbackPillar.id == 3 && (
                      <img
                        src={IcoFeedGenMedioambientalmenteSostenible}
                        alt=""
                      />
                    )}
                    {feedbackPillar.id == 4 && (
                      <img src={IcoFeedGenSocialmenteSostenible} alt="" />
                    )}
                    {feedbackPillar.id == 5 && (
                      <img src={IcoFeedGenViable} alt="" />
                    )}
                    {feedbackPillar.id == 6 && (
                      <img src={IcoFeedGenSoportable} alt="" />
                    )}
                    {feedbackPillar.id == 7 && (
                      <img src={IcoFeedGenEquitativo} alt="" />
                    )}
                    {feedbackPillar.id == 8 && (
                      <img src={IcoFeedGenVulnerable} alt="" />
                    )}
                  </span>

                  <span className="textContainer">
                    <span tw="font-bold text-xl" style={{ color: "#008080" }}>
                      {feedbackPillar.name}
                    </span>
                  </span>
                </Card>
              </div>
            </div>

            <div tw="grid grid-cols-8 gap-4 p-2">
              <div tw="flex justify-center items-center rounded-lg col-span-8 bg-[#f9f9f9] p-2 2xl:text-lg">
                <div tw="max-w-screen-xl">{feedbackPillar.content}</div>
              </div>
            </div>
          </div>
        </>
      )}

      {showEconomicPillar && (
        <>
          <Intro style={{ backgroundColor: "#F0A000" }}>
            Resultados Pilar Economico
          </Intro>

          <div tw="grid grid-cols-2 gap-2 p-6">
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <Container style={{ height: "300px" }}>
                <MyResponsiveRadialBarEconomico data={dataRadialEconomico} />
              </Container>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <Container style={{ height: "300px" }}>
                <MyResponsiveBarEconomico data={dataBarEconomico} />
              </Container>
            </div>
          </div>

          <Intro style={{ backgroundColor: "#F0A000" }}>Feedback</Intro>

          <div tw="grid grid-cols-2 gap-4 p-8">
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">

              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoEconomicaEstrategiaColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_economic_active">Estrategia</span>
                    </span>
                  </Card>
                </div>
              
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_estrategia}}>
                </div>
              
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoEconomicaClientesColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_economic_active">Clientes</span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_clientes}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoEconomicaInnovacionColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_economic_active">Innovación</span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_innovacion}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoEconomicaResultadoColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_economic_active">
                        Resultados e Impacto Económico
                      </span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_resultados}}>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showSocialPillar && (
        <>
          <Intro style={{ backgroundColor: "#867796" }}>
            Resultados Pilar Social
          </Intro>

          <div tw="grid grid-cols-2 gap-4 p-8">
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <Container style={{ height: "300px" }}>
                <MyResponsiveRadialBarSocial data={dataRadialSocial} />
              </Container>
            </div>
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <Container style={{ height: "300px" }}>
                <MyResponsiveBarSocial data={dataBarSocial} />
              </Container>
            </div>
          </div>

          <Intro style={{ backgroundColor: "#867796" }}>Feedback</Intro>

          <div tw="grid grid-cols-2 gap-4 p-8">
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoSocialResponsabilidadColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_social_active">
                        Responsabilidad Social
                      </span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_responsabilidad}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoSocialCalidadColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_social_active">
                        Calidad Alimentaria
                      </span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_calidad}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoSocialTrabajadoresColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_social_active">Trabajadores</span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_trabajadores}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoSocialDesarrolloColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_social_active">Desarrollo</span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_desarrollo}}>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showEnvironmentPillar && (
        <>
          <Intro style={{ backgroundColor: "#41AD2B" }}>
            Resultados Pilar Medio Ambiental
          </Intro>

          <div tw="grid grid-cols-2 gap-4 p-8">
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <Container style={{ height: "350px" }}>
                <MyResponsiveRadialBarAmbiental data={dataRadialAmbiental} />
              </Container>
            </div>
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <Container style={{ height: "350px" }}>
                <MyResponsiveBarAmbiental data={dataBarAmbiental} />
              </Container>
            </div>
          </div>

          <Intro style={{ backgroundColor: "#41AD2B" }}>Feedback</Intro>

          <div tw="grid grid-cols-2 gap-4 p-8">
            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoAmbientalCumplimientoColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_environment_active">
                        Cumplimiento Ambiental
                      </span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_cumplimiento}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoAmbientalEficienciaColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_environment_active">Envases</span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_eficiencia}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoAmbientalEmisionesColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_environment_active">
                        Eficiencia
                      </span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_emisiones}}>
                </div>
              </div>
            </div>

            <div tw="col-span-2 md:col-span-1 rounded-lg bg-[#f9f9f9] p-2">
              <div tw="grid grid-cols-4 gap-4 p-2">
                <div tw="rounded-lg col-span-1 bg-[#f9f9f9]">
                  <Card>
                    <span>
                      <img src={IcoAmbientalEnvasesColor} alt="" />
                    </span>

                    <span className="textContainer">
                      <span className="title_environment_active">
                        Emisiones
                      </span>
                    </span>
                  </Card>
                </div>
                <div tw="rounded-lg col-span-3 bg-[#f9f9f9] p-2 2xl:text-lg" dangerouslySetInnerHTML={{__html: evaluationFeedback.feedback_envases}}>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
