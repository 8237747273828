import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

import configData from "config.json";
import how from "images/how.png";

// Estilos Elementos Pagina
const Column = tw.div`flex flex-col items-center`;
const FAQSContainer = tw.dl` max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-4 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const TwoColumn = tw.div`pt-4 pb-10 px-4 flex justify-between items-center flex-col lg:flex-row mx-auto`;
const LeftColumn = tw.div`flex flex-col lg:block lg:w-1/6 mx-auto`;
const RightColumn = tw.div`w-full lg:mt-0 lg:pl-8`;

// Componente
export default ({}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    var faqsData = [
      {
          "id": 6,
          "question": "¿Qué es la plataforma ProgreNFood?",
          "content": "Una herramienta de medición de tu estrategia de sostenibilidad, entendida desde una perspectiva económica, social y medioambiental. Tendrás un diagnóstico completamente gratuito del impacto de tu estrategia, tus principales áreas de mejora y tu posición respecto al sector. ",
          "image": "1674150616.jpg",

      },
      {
          "id": 4,
          "question": "¿Para quién está diseñada?",
          "content": "La herramienta se ha diseñado teniendo en cuenta la realidad del sector agroalimentario. Existen además dos itinerarios de diagnóstico: uno para empresas del sector primario y otro para la agroindustria, por las diferencias que existen tanto en procesos como en impacto potencial de su estrategia.",
          "image": "4.png",

      },
      {
          "id": 3,
          "question": "¿Qué información se pide?",
          "content": "Las preguntas están dirigidas a entender las prácticas de gestión dentro de cada una de las tres vertientes de la sostenibilidad. Estas prácticas pueden ser más cualitativas (basadas en madurez de las iniciativas puestas en marcha) o cuantitativas (basadas en número de iniciativas o % de desarrollo) Las preguntas siempre tienen 4 opciones de respuesta, basadas en los niveles de madurez/rangos. <br/><br/>Esta información es 100% confidencial, y la única información que se comparte es a través de medias agregadas del sector/tamaño de empresa.<br/><br/>Preguntas de muestra:<br/><br/><strong>Sostenibilidad económica:</strong> ¿Qué porcentaje de las ventas proceden de productos/servicios creados en los últimos 3 años?<br/><br/><strong>Sostenibilidad Social:</strong> ¿Qué porcentaje del total de proveedores de la empresa son considerados proveedores locales (de tu propia comunidad o limítrofe)?<br/></br><strong>Sostenibilidad medioambiental:</strong> ¿Cuánto se ha reducido el consumo energético de la empresa (kWh/€ ventas netas) en los últimos 3 años?</strong></strong>",
          "image": "3.png",

      },

  ];
      setFaqs(faqsData);

  }, []);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>

        <Column>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <TwoColumn>
                    <RightColumn dangerouslySetInnerHTML={{__html: faq.content}}></RightColumn>
                  </TwoColumn>
                </Answer>
              </FAQ>

              
            ))}
            <div tw="pt-5">
            <img tw="w-full" src={how} />        
            </div>
            
          </FAQSContainer>
          
        </Column>
      
    </Container>
  );
};
