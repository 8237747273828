import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {

  SectionHeadingLite,
} from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";


import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";


const Container = tw.div`relative`;

const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeadingLite)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-4 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   */

  const cards = [
    {
      imageSrc: CustomizeIconImage,
      title: "Sistema Experto",
      description:
        "Te acompañaremos en la medición de los pilares de la sostenibilidad y el diseño de planes de acción",
    },
    {
      imageSrc: FastIconImage,
      title: "Knowledge Hub",
      description:
        "Recursos formativos para que puedas aprender sobre los factores más relevantes para tu negocio",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Buenas Prácticas",
      description:
        "Conoce que están haciendo otras organizaciones en torno a la sostenibilidad y los resultados obtenidos",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Servicios de Mejora",
      description:
        "Todo un ecosistema de profesionales a tu alcance para que puedas acelerar tus iniciativas",
    },
  ];

  return (
    <Container>
      <FourColumnContainer>
        <Heading>
          Una herramienta que te ofrece soluciones para todas tus necesidades en
          materia de <span tw="text-primary-500">Sostenibilidad</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </FourColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
