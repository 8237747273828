import tw from "twin.macro";

export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
export const SectionHeadingNoBold = tw.h2`text-3xl sm:text-4xl tracking-wide text-center`
export const SectionHeadingNoBold2xl = tw.h2`text-2xl sm:text-2xl tracking-wide text-center`
export const SectionHeadingLite = tw.h2`text-3xl sm:text-4xl tracking-wide text-center`
export const SectionHeadingLite2xl = tw.h2`text-2xl sm:text-2xl tracking-wide text-center`
export const SectionHeadingLitexl = tw.h2`text-xl sm:text-xl tracking-wide text-center`
export const SectionHeadingMin = tw.h2`text-xl sm:text-xl tracking-wide`

export const Subheading = tw.h5`font-bold text-primary-500`
