import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HeroHeaderValida from "components/pds/HeroHeaderValida";
import HeroContentValida from "components/pds/HeroContentValida";
import FeaturesValida from "components/pds/FeaturesValida";
import GetStarted from "components/pds/GetStarted";
import Footer from "components/pds/FooterFiveColumns.js";

export default () => (
  <AnimationRevealPage>
    <HeroHeaderValida />
    <HeroContentValida />
    <FeaturesValida />
    <GetStarted />
    <Footer />
  </AnimationRevealPage>
);