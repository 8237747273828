import { atom, useRecoilState } from 'recoil';

const userTokenState = atom({
  key: 'user-token-state',
  default: '',
});

function useUserToken() {
  const [userToken, setToken] = useRecoilState(userTokenState);

  function updateToken(token) {
    console.log('actualizando token');
    console.log(token);
    setToken(token);
    console.log('valor actualizado');
    console.log(token);
  }

  return [userToken, { updateToken }];
}

export default useUserToken;