import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import configData from "config.json";
import EvaluationService from "services/evaluation.service";
import toast from "react-hot-toast";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import FinishedTaskImg from "images/bg_fin_caracterizacion.jpg";

import IcoCaracterizacionColor from "../../images/IcoCaracterizacionColor.png";
import IcoEconomicaColor from "../../images/IcoEconomicaColor.png";
import IcoEconomicaGris from "../../images/IcoEconomicaGris.png";
import IcoSocialColor from "../../images/IcoSocialColor.png";
import IcoSocialGris from "../../images/IcoSocialGris.png";
import IcoMedioambientalColor from "../../images/IcoMedioambientalColor.png";
import IcoMedioambientalGris from "../../images/IcoMedioambientalGris.png";

const Container = tw.div`relative mb-4`;
const ColumnContainer = tw.div`pt-1 max-w-lg`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-center  mt-4 lg:mt-1 flex-col sm:flex-row`;
const Link = tw.a`rounded w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-2 mt-1 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0  font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-center bg-[#424445] text-gray-100 shadow-lg hocus:bg-[#41AD2B] hocus:text-gray-200`;

let Intro = styled.div`
  ${tw`md:w-1/2 lg:w-4/5  p-4 pl-4 text-center mt-6 rounded-lg text-2xl text-gray-700`}
`;

const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 `}
`;
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/4 px-4 flex`}
`;
const Card = styled.div`
  ${tw`flex 2xl:flex-col xl:flex-col lg:flex-col md:flex-col flex-row w-full mx-auto items-center px-6 py-2  rounded-lg mt-0`}
  .imageContainer {
    ${tw`border-0 border-yellow-600 text-center rounded-full p-2 flex-shrink-0 relative`}
    img {
      ${tw`w-6 h-6`}
    }
  }
  .textContainer {
    ${tw`mt-0 text-center  p-1`}
  }
  .textContainerGray {
    ${tw`mt-0 text-center p-1`}
  }
  .title {
    ${tw`mt-2  text-xl leading-none text-gray-700 `}
  }
  .title_characterization_active {
    ${tw`mt-0  text-base leading-none text-[#494948] `}
  }
  .title_economic_active {
    ${tw`mt-0  text-base leading-none text-[#F0A000] `}
  }
  .title_social_active {
    ${tw`mt-0  text-base leading-none text-[#867796] `}
  }
  .title_environment_active {
    ${tw`mt-0  text-base leading-none text-[#41AD2B] `}
  }
  .title_gray {
    ${tw`mt-0  text-base leading-none text-gray-400 `}
  }
  .horizontal {
    ${tw`border-yellow-600 h-1`}
  }
  .progress_gray_color {
    ${tw`bg-[#494948 ]`}
  }
  .progress_orange_color {
    ${tw`bg-[#F0A000 ]`}
  }
  .progress_blue_color {
    ${tw`bg-[#867796 ]`}
  }
  .progress_green_color {
    ${tw`bg-[#41AD2B]`}
  }
`;

/**
 * Definicion del componente
 */
export default () => {
  const [startedEvaluation, setStartedEvaluation] = useState([]);
  const navigate = useNavigate();

  /**
   * Ejecutado al cargar la página
   */
  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user_token"));
    let baseURL = configData.API_BASE_URL + "started_evaluation/get_current";
    axios
      .get(baseURL, { headers: { Authorization: `Bearer ${userToken}` } })
      .then((response) => {
        setStartedEvaluation(response.data.data);
        let d = response.data.data;

        // Actualización del interface
        //update_gui(d);
      })
      .catch((exeption) => {
        toast.error("Error de Acceso");
        logout_system();
      });
  }, []);

  const update_gui = (d) => {
    console.log(d);
  };

  /**
   * Salida de la parte privada de la aplicación
   */
  const logout_system = () => {
    localStorage.removeItem("user_token");
    window.location.reload();
  };

  /**
   * Comienzo del siguiente pillar
   */
  const goToNextPillar = () => {
    let updated_started_evaluation = {
      id: startedEvaluation.id,
      pillar_id: 2,
      factor_id: configData.FACTOR_ESTRATEGIA,
      current_question_position: 0,
      current_question_id: 0,
    };

    EvaluationService.update_started_evaluation(updated_started_evaluation)
      .then((value) => {
        if (value.data.success) {
          setStartedEvaluation(value.data.data);
          navigate("/mide_evaluacion_pilar");
        } else {
          toast.error("Error de Acceso en actualización");
        }
      })
      .catch((exception) => {
        toast.error("Error de Conexión");
        logout_system();
      });
  };

  // Definición de los Pilares
  const cards = [
    {
      pilar_id: 1,
      tag: "characterization_pillar",
      image_src_active: IcoCaracterizacionColor,
      image_src_inactive: IcoCaracterizacionColor,
      title: "CARACTERIZACIÓN",
      class_title_active: "title_characterization_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_gray_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 2,
      tag: "economic_pillar",
      image_src_active: IcoEconomicaColor,
      image_src_inactive: IcoEconomicaGris,
      title: "Pilar ECONÓMICO",
      class_title_active: "title_economic_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_orange_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 3,
      tag: "social_pillar",
      image_src_active: IcoSocialColor,
      image_src_inactive: IcoSocialGris,
      title: "Pilar SOCIAL",
      class_title_active: "title_social_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_blue_color",
      class_progress_background: "bg-gray-200",
    },
    {
      pilar_id: 4,
      tag: "environment_pillar",
      image_src_active: IcoMedioambientalColor,
      image_src_inactive: IcoMedioambientalGris,
      title: "Pilar MEDIOAMBIENTAL",
      class_title_active: "title_green_active",
      class_title_inactive: "title_gray",
      class_progress_foreground: "progress_green_color",
      class_progress_background: "bg-gray-200",
    },
  ];

  // Generación de la fila con la lista de Pilares
  const gen_card = (card) => {
    const get_progressbar = (card) => {
      if (card.pilar_id == 1) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw=" h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: "100%" }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 2) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: "0%" }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 3) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: "0%" }}
            ></div>
          </div>
        );
      } else if (card.pilar_id == 4) {
        return (
          <div tw="w-full rounded-full h-1 bg-gray-400">
            <div
              tw="bg-blue-600 h-1 rounded-full"
              className={card.class_progress_foreground}
              style={{ width: "0%" }}
            ></div>
          </div>
        );
      }
    };

    if (card.pilar_id == 1) {
      return (
        <Card>
          <span className="imageContainer">
            <img src={card.image_src_active} alt="" />
          </span>

          {get_progressbar(card)}

          <span className="textContainer">
            <span className={card.class_title_active}>{card.title}</span>
          </span>
        </Card>
      );
    } else {
      if (startedEvaluation["finished_" + card.tag] == 1) {
        return (
          <Card>
            <span className="imageContainer">
              <img src={card.image_src_active} alt="" />
            </span>

            {get_progressbar(card)}

            <span className="textContainer">
              <span className={card.class_title_active}>{card.title}</span>
            </span>
          </Card>
        );
      } else {
        return (
          <Card>
            <span className="imageContainer">
              <img src={card.image_src_inactive} alt="" />
            </span>

            {get_progressbar(card)}

            <span className="textContainerGray">
              <span className={card.class_title_inactive}>{card.title}</span>
            </span>
          </Card>
        );
      }
    }
  };

  return (
    <Container tw="">
      <FourColumnContainer tw="mt-10 sm:mt-0">
        {cards.map((card, i) => (
          <Column key={i}>{gen_card(card)}</Column>
        ))}
      </FourColumnContainer>

      <div tw=" ">
        <div tw="grid grid-cols-12 gap-x-2">
          <div tw="col-span-1"></div>
          <div tw="col-span-10 md:col-span-5 flex justify-center items-center bg-[#f5f7f6] rounded-lg">
            <Intro tw="mb-8">
              <strong>¡ Enhorabuena !</strong> <br />
              <br />
              Has completado la <strong>Fase de Caracterización</strong>. <br />
              <br />
              Ahora ya puedes iniciar la siguiente fase.
            </Intro>
          </div>

          <div tw="collapse md:visible col-span-5 flex justify-center items-center rounded-lg">
            <img tw="w-full rounded-lg" src={FinishedTaskImg} alt="" />
          </div>
          <div tw="collapse md:visible col-span-1"></div>
        </div>

        <div tw="grid grid-cols-12">
          <div tw="col-span-12 flex justify-center items-center">
            <LinksContainer tw="mt-10 ">
              <PrimaryLink href="#" onClick={goToNextPillar}>
                CONTINUAR CON PILAR ECONÓMICO {">>"}
              </PrimaryLink>
            </LinksContainer>
          </div>
        </div>
      </div>
      <br />
      <br />
    </Container>
  );
};
